import React, { FunctionComponent } from 'react';

import { formatDateTimeHuman } from '../../../shared/utils/dateHelper';
import Column from '../../../shared/components/atoms/Column';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUserCanPlaceOrder } from 'User/selectors';

type Props = {
  placedAt: string;
  submittedAt: string;
  submittedBy: string | undefined | null;
  submittedForCrfAt: string;
  submittedForCrfBy: string | undefined | null;
  downloadedCrfAt: string;
  downloadedCrfBy: string | undefined | null;
};

const Placed: FunctionComponent<React.PropsWithChildren<Props>> = ({
  placedAt,
  submittedAt,
  submittedBy,
  submittedForCrfAt,
  submittedForCrfBy,
  downloadedCrfAt,
  downloadedCrfBy
}) => {
  const theme = useTheme();
  const canPlaceOrder = useSelector(selectUserCanPlaceOrder);
  return (
    <div className="row no-gutters">
      <Column>
        {submittedForCrfBy ? (
          <p style={{ color: theme.colours.secondary }}>
            Order form requested{submittedForCrfBy && <span>&nbsp;by {submittedForCrfBy}</span>}&nbsp;on {formatDateTimeHuman(submittedForCrfAt)}
          </p>
        ) : (
          <p style={{ color: theme.colours.secondary }}>
            Submitted{submittedBy && <span>&nbsp;by {submittedBy}</span>}&nbsp;on {formatDateTimeHuman(submittedAt)}
          </p>
        )}
        {downloadedCrfBy && canPlaceOrder && (
          <p style={{ color: theme.colours.secondary }}>
            Order form downloaded{downloadedCrfBy && <span>&nbsp;by {downloadedCrfBy}</span>}&nbsp;on {formatDateTimeHuman(downloadedCrfAt)}
          </p>
        )}
        <p style={{ color: theme.colours.secondary }}>
          Accepted by Front Office on {formatDateTimeHuman(placedAt)}. If you have any queries please email{' '}
          <a href={`mailto:${theme.content.contactInfo.provisioning.email}`}>
            {theme.content.contactInfo.provisioning.email}
          </a>
        </p>
      </Column>
    </div>
  );
};

export default Placed;
