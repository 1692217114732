import React, { FC, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

interface IHtmlEditorProps {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

export const modules = {
  toolbar: [
    [{size: [ 'small', false, 'large', 'huge']}],
    ['bold', 'italic', 'underline', 'strike'],
    [{ indent: '-1'}, { indent: '+1' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { align: [false, 'center', 'right', 'justify']}],
    ['link', 'video'],
    ['clean'],
  ],
};

// Formats control what types of content and formatting the editor will support. e.g bold is allowed.  
export const formats = ['size', 'bold', 'italic', 'underline', 'strike', 'list', 'indent', 'align', 'link', 'video'];

const UnstyledHtmlEditor: FC<React.PropsWithChildren<IHtmlEditorProps>> = ({ className, value, onChange }) => {
  const theme = useTheme();
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: theme.htmlEditorTheme || 'snow',
        modules,
        formats,
      });

      quillRef.current.on('text-change', () => {
        onChange(quillRef.current?.root.innerHTML || '');
      });
    }
  }, [onChange, theme]);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.root;
      if (editor.innerHTML !== value) {
        editor.innerHTML = value;
      }
    }
  }, [value]);

  return <div className={className} ref={editorRef} />;
};

export const HtmlEditor = styled(UnstyledHtmlEditor)`
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 1px solid #4f4f4f;
  }
`;
