import { AEndCosts } from 'Order/OrderBuilder/QuoteSummary/MarginAndCosts/AEndCosts';
import { BEndCosts } from 'Order/OrderBuilder/QuoteSummary/MarginAndCosts/BEndCosts';
import { FttpAggCharge } from 'Order/OrderBuilder/QuoteSummary/MarginAndCosts/FttpAggregationCharge';
import { FttxConnection } from 'Order/OrderBuilder/QuoteSummary/MarginAndCosts/FttxConnection';
import { DiaCosts } from 'Order/OrderBuilder/QuoteSummary/MarginAndCosts/DiaCosts';
import React from 'react';
import { ProductType } from 'Quotes/types/productTypes';
import { OverallConnectionType } from 'Quotes/types/connectionType';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { CostRow } from './CostRow';

export function CircuitCosts(props: {
  label?: string;
  productType: ProductType;
  connectionType: OverallConnectionType;
  selectedPrice: IPriceData;
  displayFTTPAggregationCharge: boolean;
  ipType: IPType | undefined;
  managedDia: boolean;
  diaIpAllocation: any;
  quote?: IQuote;
  mdiaAnnualCost: number;
  mdiaRouterCost: number;
  annualIPCharge: number;
}) {
  return (
    <>
      <div className={'internal-costs-header'} data-testid={`${props.label}-cost-header`}>
        {props.label}
      </div>
      <AEndCosts
        productType={props.productType}
        connectionType={props.connectionType}
        selectedPrice={props.selectedPrice}
      />
      <BEndCosts
        productType={props.productType}
        connectionType={props.connectionType}
        selectedPrice={props.selectedPrice}
      />
      {props.productType === ProductType.OpticalP2P && (
        <CostRow
          label="Cross Connect"
          install={props.selectedPrice.bandwidth_cost?.install ?? 0}
          annual={props.selectedPrice.bandwidth_cost?.annual ?? 0}
          />
      )}
      <FttpAggCharge
        displayFTTPAggregationCharge={props.displayFTTPAggregationCharge}
        selectedPrice={props.selectedPrice}
      />
      <FttxConnection
        connection={props.connectionType}
        productType={props.productType}
        selectedPrice={props.selectedPrice}
      />
      <DiaCosts
        productType={props.productType}
        ipType={props.ipType}
        managedDia={props.managedDia}
        diaIpAllocation={props.diaIpAllocation}
        selectedPrice={props.selectedPrice}
        quote={props.quote}
        label={props.label}
        mdiaAnnualCost={props.mdiaAnnualCost}
        mdiaRouterCost={props.mdiaRouterCost}
        annualIPCharge={props.annualIPCharge}
      />
    </>
  );
}
