import { selectSelectedPriceAnnualCost } from 'Quotes/selectors';
import { ProductType } from 'Quotes/types/productTypes';
import React from 'react';
import CostDisplay from './CostDisplay';
import { IPriceData } from '../../../../../types/store';

interface CostsInternalAnnualProps {
  selectedPriceAnnualCost: ReturnType<typeof selectSelectedPriceAnnualCost>;
  selectedPrice: IPriceData;
  isFTTXQuote: boolean;
  portsCostAnnual: number;
  bandwidthCostAnnual: number;
  productType: ProductType | null | undefined;
  geaCableLinkAnnual: number;
  isManagedDIA: boolean;
  mdiaServiceCost: number;
}

export const CostsInternalAnnual: React.FC<CostsInternalAnnualProps> = ({
  selectedPriceAnnualCost,
  productType,
  portsCostAnnual,
  bandwidthCostAnnual,
  geaCableLinkAnnual,
  isManagedDIA,
  isFTTXQuote,
  mdiaServiceCost,
}) => {

  return (
    <>
      <CostDisplay label="A-End Supplier Costs" cost={selectedPriceAnnualCost.A} />
      <CostDisplay label="B-End Supplier Costs" cost={selectedPriceAnnualCost.B} />
      {portsCostAnnual > 0 && <CostDisplay label="Port Costs" cost={portsCostAnnual} />}
      {productType === ProductType.OpticalP2P && <CostDisplay label="Cross Connect Costs" cost={bandwidthCostAnnual} />}
      {productType === ProductType.DIA && <CostDisplay label="Bandwidth Costs" cost={bandwidthCostAnnual} />}
      {isFTTXQuote && geaCableLinkAnnual > 0 && <CostDisplay label="GEA Cablelink Costs" cost={geaCableLinkAnnual} />}
      {productType === ProductType.DIA && isManagedDIA && mdiaServiceCost > 0 && (
        <CostDisplay label="Managed DIA services Costs" cost={mdiaServiceCost} />
      )}
    </>
  );
};
