import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { IOrder } from 'Order/types/store';
import { ProductType } from 'Quotes/types/productTypes';
import { IPriceData, IQuote } from 'Quotes/types/store';
import Column from 'shared/components/atoms/Column';
import { P2P } from './P2P';
import { DIA } from './DIA';
import { P2NNI } from './P2NNI';
import { P2CCT } from './P2CCT';
import { OpticalP2P } from './OpticalP2P';
import { NNI2CCT } from './NNI2CCT';
import { Diversity } from './Diversity';
import { TermAndPrice } from './TermAndPrice';
import { capitalize } from 'lodash';
import { connect } from 'react-redux';
import { IAppState } from 'reducers';
import { IUserRoleFromBackend } from 'User/types/role';
import { isSSEUserFromBackendRoles } from 'Admin/utils/companyRolesRows';
import DownloadOrder from './DownloadOrder';
import { useCopyToClipboard } from 'shared/hooks/useCopyToClipboard';
import { OrderId } from './OrderId';
import { OrderIdDetails } from './OrderIdDetails';
import { OrderProduct } from './OrderProduct';
import { OrderBandwidth } from './OrderBandwidth';

interface IQuoteSummary {
  className?: string;
  quoteId: string;
  order: IOrder;
  bearer: BearerType | undefined;
  bandwidth: string;
  bulkQuoteId?: string;
  productType: ProductType;
  quote: IQuote;
  allRoles: IUserRoleFromBackend[];
  selectedPrice: IPriceData;
}

const QuoteSummary: FunctionComponent<React.PropsWithChildren<IQuoteSummary>> = ({
  className,
  quoteId,
  order,
  bearer,
  bandwidth,
  bulkQuoteId,
  productType,
  quote,
  allRoles,
  selectedPrice,
}) => {
  const isSSEUser = isSSEUserFromBackendRoles(allRoles);
  const { copy, isRecentlyCopied } = useCopyToClipboard();

  const OrderLocation = () => {
    switch (productType) {
      case ProductType.P2P:
        // Handle Product Type P2P as Optical P2P
        if (quote.location.aEnd.dataCentreReference && quote.location.bEnd.dataCentreReference) {
          return <OpticalP2P />;
        }
        return (
          <P2P
            isSSEUser={isSSEUser}
            productType={productType}
            bearer={bearer}
            bandwidth={bandwidth}
            quote={quote}
            selectedPrice={selectedPrice}
          />
        );

      case ProductType.DIA:
        return <DIA isSSEUser={isSSEUser} bearer={bearer} bandwidth={bandwidth} productType={productType} />;

      case ProductType.P2NNI:
        return (
          <P2NNI
            aEndAccessMethod={quote.aEndAccessMethod}
            aEndBandwidth={quote.aEndBandwidth}
            bEndAccessMethod={quote.bEndAccessMethod}
            bEndBandwidth={quote.bEndBandwidth}
            bandwidth={bandwidth}
            bearer={bearer}
            connectionType={quote.connectionType}
            isSSEUser={isSSEUser}
            productType={productType}
            quote={quote}
            selectedPrice={selectedPrice}
          />
        );

      case ProductType.P2CCT:
        return <P2CCT />;

      case ProductType.NNI2CCT:
        return <NNI2CCT />;

      case ProductType.OpticalP2P:
        return <OpticalP2P />;

      default:
        return null;
    }
  };

  return (
    <div className={`${className} quote-summary__wrapper`}>
      <div className="row">
        <OrderId
          onClick={() => copy(capitalize(order.shortId))}
          string={order.shortId}
          recentlyCopied={isRecentlyCopied}
        />
        <Column defaultWidth={12} mdWidth={3} classNames={['text-md-right', 'mt-2', 'mb-2', 'md-m-0']}>
          <DownloadOrder buttonText='Download PDF'/>
        </Column>
      </div>
      <div className="row pb-3">
        <OrderIdDetails
          origin={quote.origin}
          id={quoteId}
          string={quote.shortId}
          bulkQuoteId={bulkQuoteId}
          shortBulkQuoteId={quote.shortBulkQuoteId}
          placedAt={order.placedAt}
          createdAt={order.createdAt}
          cerillionStage={order.cerillion_stage}
          state={order.state}
          cerillionBasketId={order.cerillion_basket_id}
        />
      </div>
      <div className="row no-gutters">
        <OrderProduct quote={quote} productType={productType} />
      </div>
      <OrderLocation />

      {/*Will slowly be moving OrderBandwidth to the specific product types in
      OrderLocation. P2NNI completed therefore excluding this from showing for P2NNI*/}
      {productType !== ProductType.P2NNI && productType !== ProductType.P2P && productType !== ProductType.DIA && (
        <OrderBandwidth
          connectionType={quote.connectionType}
          bandwidth={bandwidth}
          selectedPrice={selectedPrice}
          quote={quote}
          bearer={bearer}
          aEndAccessMethod={quote.aEndAccessMethod}
          bEndAccessMethod={quote.bEndAccessMethod}
          aEndBandwidth={quote.aEndBandwidth}
          bEndBandwidth={quote.bEndBandwidth}
          productType={productType}
        />
      )}

      <Diversity />
      <TermAndPrice />
    </div>
  );
};

const StyledQuoteSummary = styled(QuoteSummary)`
  h1 {
    font-size: 2.6em;
  }

  #quote-link {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    text-decoration: underline;
    color: white;
  }

  #bulk-quote-link {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    text-decoration: underline;
    color: white;
  }

  .bulk-quote-arrow {
    vertical-align: middle;
  }

  .pop,
  .discount {
    font-size: 0.75rem;
    font-family: 300;
  }

  .sub-heading {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .cabling-required {
    text-decoration: underline;
  }

  .margin-separator {
    padding: 0 0.25rem;
  }

  .copy-to-clipboard {
    cursor: pointer;
  }
`;

const mapStateToProps = ({ user: { allRoles } }: IAppState) => ({
  allRoles,
});

export default connect(mapStateToProps)(StyledQuoteSummary);
