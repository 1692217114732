import { ContactIdentifierType } from 'Order/OrderBuilder/shared/components/ExistingContactOptions';
import produce from 'immer';
import { IMultiQuoteCopySectionPayload, OrderAction, OrderActionTypes, SetSiteCoords } from './types/actions';
import { IOrder, IOrderState } from './types/store';
import {
  AccessNotice,
  APINewNNIRequest,
  AsbestosRegister,
  ConnectorType,
  Duplexity,
  IOrderLocation,
  ISecondarySiteConfig,
  ISiteConfig,
  ISiteContact,
  MediaType,
  PowerType,
} from './types/location';
import { IContact } from './types/contact';
import { Note } from './types/note';
import { IBillingContactAndAddress, IPContact, SecondaryIPContact } from './types/formFields';
import IOrderRecord, {
  IOrderDataAttributes,
  OrderStatus,
  StateHistory,
  VLAN_ID_INTERNALLY_ASSIGNED,
} from './types/orderRecord';
import { cloneObject } from 'shared/utils/objectHelper';
import { AddressType, QuoteOrigin } from 'Quotes/types/store';
import { RequestLifecycle } from 'shared/actions';
import IQuoteRecord, { IQuotePricedOrOrdered } from '../Quotes/types/quoteRecord';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { maskSSEUserEmail } from 'shared/utils/maskEmail';
import { ProductType } from 'Quotes/types/productTypes';
import { getOrigin } from 'Quotes/reducer';
import { Supplier } from 'Quotes/types/supplier';
import { isVlanIdInternallyAssigned } from './OrderBuilder/shared/utils/isVlanIdInternallyAssigned';
import { isStaticIPWanIPCompatible } from 'Quotes/QuoteBuilder/utils/ip';
import { deserialiseJsonApiResponse } from 'Request/utils/deserialiseJsonApiResponse';
import dayjs from 'dayjs';
import { transformMessagesForReducer } from 'shared/utils/messagesHelpers';
import { Response } from 'Request';
import { IOrderTemplateState } from './types/orderTemplate';
import { mapNewNNIRequestFromAPI } from './types/mappers';
import { APISecondarySiteConfig } from 'Order/crud/updateOrder';
import { filterIncludedBy } from 'Request/utils/filterIncludedBy';

function isYes(val: string | null | undefined): boolean {
  return val === 'YES';
}

type MapLegacyNameToFirstNameSurname = (name?: string) => {
  firstName: string;
  surname: string;
};

type LegacyAwareNameTransform = (input: { firstName?: string; surname?: string; legacyName?: string }) => {
  firstName: string;
  surname: string;
};

export const mapLegacyNameToFirstNameSurname: MapLegacyNameToFirstNameSurname = (name) => {
  if (!name || typeof name !== 'string') {
    return {
      firstName: '',
      surname: '',
    };
  }

  const split = name.split(' ');

  return {
    firstName: split[0],
    surname: split.slice(1).join(' '),
  };
};

export const legacyAwareNameTransform: LegacyAwareNameTransform = (input) => {
  if (!input.firstName && !input.surname) {
    return mapLegacyNameToFirstNameSurname(input.legacyName);
  }
  return {
    firstName: input.firstName || '',
    surname: input.surname || '',
  };
};

const replaceBillingAndContact = (attributes: IOrderRecord['data']['attributes']): IBillingContactAndAddress => {
  if (![OrderStatus.CANCELLED, OrderStatus.PLACED].includes(attributes.state)) {
    return {
      firstName: '',
      surname: '',
      phoneNumber: '',
      email: '',
      address: '',
      purchaseOrderRef: attributes.customer_reference || '',
    };
  }

  const convertedName = legacyAwareNameTransform({
    legacyName: attributes.billing_contact_name,
    firstName: attributes.billing_contact_first_name,
    surname: attributes.billing_contact_surname,
  });

  return {
    firstName: convertedName.firstName,
    surname: convertedName.surname,
    phoneNumber: attributes.billing_contact_telephone || '',
    email: attributes.billing_contact_email || '',
    address: attributes.billing_contact_address || '',
    purchaseOrderRef: attributes.customer_reference || '',
  };
};

const getDefaultConnectorForPort = (port: BearerType | undefined) => {
  if (port === BearerType.SMALL) {
    return ConnectorType.RJ45;
  } else if (port === BearerType.MEDIUM || port === BearerType.LARGE) {
    return ConnectorType.LC;
  } else {
    return null;
  }
};

function setSiteConfigDefaults(siteConfig: ISiteConfig | undefined, port: (BearerType & string) | undefined) {
  if (!siteConfig) return;

  if (!siteConfig.connectorType) {
    siteConfig.connectorType = getDefaultConnectorForPort(port);
  }
  if (port === BearerType.LARGE) {
    siteConfig.autoNegotiation = false;
  }
}

export const orderDefaults = (orderState: IOrderState, quoteAttributes: IQuoteRecord['data']['attributes']) => {
  const { order } = orderState;

  setSiteConfigDefaults(order.locationA.siteConfig, quoteAttributes.a_end_port);
  setSiteConfigDefaults(order.locationB.siteConfig, quoteAttributes.b_end_port);
  setSiteConfigDefaults(order.locationA.secondarySiteConfig, quoteAttributes.a_end_port);
  setSiteConfigDefaults(order.locationB.secondarySiteConfig, quoteAttributes.b_end_port);

  return orderState;
};

const replaceOrderContact = (attributes: IOrderRecord['data']['attributes']): IContact => {
  const convertedName = legacyAwareNameTransform({
    legacyName: attributes.contact_name,
    firstName: attributes.contact_first_name,
    surname: attributes.contact_surname,
  });

  return {
    firstName: convertedName.firstName,
    surname: convertedName.surname,
    phoneNumber: attributes.contact_telephone || '',
    email: attributes.contact_email || '',
  };
};

const replaceOperationalContact = (attributes: IOrderRecord['data']['attributes']): IContact => {
  const convertedName = legacyAwareNameTransform({
    legacyName: attributes.service_ops_name,
    firstName: attributes.service_ops_first_name,
    surname: attributes.service_ops_surname,
  });

  return {
    firstName: convertedName.firstName,
    surname: convertedName.surname,
    phoneNumber: attributes.service_ops_telephone || '',
    email: attributes.service_ops_email || '',
  };
};

const replaceDeliveryContact = (attributes: IOrderRecord['data']['attributes']): IContact => {
  if (![OrderStatus.CANCELLED, OrderStatus.PLACED].includes(attributes.state)) {
    return {
      firstName: '',
      surname: '',
      phoneNumber: '',
      email: '',
    };
  }

  const convertedName = legacyAwareNameTransform({
    legacyName: attributes.order_delivery_name,
    firstName: attributes.order_delivery_first_name,
    surname: attributes.order_delivery_surname,
  });

  return {
    firstName: convertedName.firstName,
    surname: convertedName.surname,
    phoneNumber: attributes.order_delivery_telephone || '',
    email: attributes.order_delivery_email || '',
  };
};

export const replaceIPContact = (attributes: IOrderRecord['data']['attributes']): IPContact => {
  const convertedName = legacyAwareNameTransform({
    legacyName: attributes.ip_contact_name,
    firstName: attributes.ip_contact_first_name,
    surname: attributes.ip_contact_surname,
  });

  return {
    address: attributes.ip_contact_address || '',
    email: attributes.ip_contact_email || '',
    firstName: convertedName.firstName,
    surname: convertedName.surname,
    nic_ripe_handle: attributes.ip_contact_nic_ripe_handle || '',
    organisation: attributes.ip_contact_organisation || '',
    telephone: attributes.ip_contact_telephone || '',
  };
};

export const replaceNotes = (attributes: IOrderRecord['data']['attributes'], stateHistory: StateHistory): Note[] => {
  const accumulatingStates = [OrderStatus.IN_REVIEW, OrderStatus.ORDER_FORM_REQUESTED, OrderStatus.RETURNED];
  const keyStateTransitions = (stateHistory || []).reduce<StateHistory>((acc, item, index, source) => {
    if ([OrderStatus.DRAFT, OrderStatus.READY].includes(item.state)) {
      return acc;
    }

    if ([OrderStatus.PLACED, OrderStatus.CANCELLED].includes(item.state)) {
      acc.push(item);
      return acc;
    }

    if (accumulatingStates.includes(item.state) && source[index - 1].state !== item.state) {
      acc.push(item);
      return acc;
    }

    return acc;
  }, []);

  const notes = (attributes.notes || []).map((note) => ({
    createdAt: note.created_at,
    createdBy: note.created_by,
    message: note.message,
  }));

  return [
    ...keyStateTransitions.map((item) => ({
      createdAt: item.created_at,
      createdBy: item.updated_by,
      state: item.state,
    })),
    ...notes,
  ]
    .sort((a, b) => {
      if (dayjs(a.createdAt).isBefore(b.createdAt)) {
        return -1;
      }
      if (dayjs(a.createdAt).isAfter(b.createdAt)) {
        return 1;
      }
      return 0;
    })
    .map((item) => {
      item.createdBy = maskSSEUserEmail(item.createdBy);
      return item;
    });
};

export const replaceAsbestosRegister = (
  productType: ProductType,
  end: 'A' | 'B',
  asbestosRegister?: null | AsbestosRegister
): AsbestosRegister => {
  if (productType === ProductType.NNI2CCT) {
    return AsbestosRegister.NA;
  }

  if (end === 'A' && productType === ProductType.P2NNI) {
    return AsbestosRegister.NA;
  }

  if (end === 'B' && (productType === ProductType.DIA || productType === ProductType.P2CCT)) {
    return AsbestosRegister.NA;
  }

  if (!asbestosRegister) {
    return AsbestosRegister.NotBuiltPriorTo2000;
  }

  return asbestosRegister;
};

interface SiteConfigParams {
  a_end_floor?: string | undefined | null;
  a_end_room?: string | undefined | null;
  a_end_suite?: string | undefined | null;
  a_end_rack?: string | undefined | null;
  a_end_connector_type?: string | undefined | null;
  a_end_media_type?: string | undefined | null;
  a_end_power?: string | undefined | null;
  a_end_duplexity?: string | null | undefined;
  a_end_auto_negotiation?: string | undefined | null;
  a_end_vlan_tagging?: string | null | undefined;
  a_end_vlan_id?: string | null | undefined;
  shadow_vlan?: string | null | undefined;
  shadow_vlan_n_n_i?: string | undefined;
  nni_job_reference?: string | null;
  nni_idn_number?: string | null;
  shadow_nni_job_reference?: string | null;
  shadow_nni_idn_number?: string | null;
  new_nni_requests?: APINewNNIRequest[] | undefined;
  quoteData?: IQuoteRecord['data'];
}

function getSiteConfig(attributes: SiteConfigParams, isManagedDIA: boolean | undefined) {
  return {
    floor: attributes.a_end_floor || '',
    room: attributes.a_end_room || null,
    suite: attributes.a_end_suite || '',
    rackId: attributes.a_end_rack || '',
    connectorType: isManagedDIA ? ConnectorType.RJ45 : (attributes.a_end_connector_type as ConnectorType | null),
    mediaType: isManagedDIA ? MediaType.TX : (attributes.a_end_media_type as MediaType | null),
    powerType: isManagedDIA ? PowerType.AC : ((attributes.a_end_power || PowerType.AC) as PowerType),
    duplexity: (attributes.a_end_duplexity as Duplexity | null) || Duplexity.Full,
    autoNegotiation: isManagedDIA
      ? true
      : attributes.a_end_auto_negotiation !== null && attributes.a_end_auto_negotiation !== undefined
      ? isYes(attributes.a_end_auto_negotiation)
      : undefined,
    vlanTagging: isYes(attributes.a_end_vlan_tagging),
    vlanId: isVlanIdInternallyAssigned(attributes.a_end_vlan_id)
      ? VLAN_ID_INTERNALLY_ASSIGNED
      : attributes.a_end_vlan_id || null,
    shadowVLANTagging: isYes(attributes.shadow_vlan),
    shadowVLANId: isVlanIdInternallyAssigned(attributes.shadow_vlan_n_n_i)
      ? VLAN_ID_INTERNALLY_ASSIGNED
      : attributes.shadow_vlan_n_n_i,
    nni_job_reference: attributes.nni_job_reference,
    nni_idn_number: attributes.nni_idn_number,
    shadow_nni_job_reference: attributes.shadow_nni_job_reference,
    shadow_nni_idn_number: attributes.shadow_nni_idn_number,
    new_nni_requests: attributes.new_nni_requests ? attributes.new_nni_requests.map(mapNewNNIRequestFromAPI) : [],
  };
}

function getSecondarySiteConfig(
  attributes: APISecondarySiteConfig | undefined,
  circuitId: string,
  end: APISecondarySiteConfig['end'],
  isManagedDIA: boolean | undefined
): ISecondarySiteConfig | undefined {
  return {
    circuitId: circuitId,
    end: end,
    floor: attributes?.floor || '',
    room: attributes?.room || null,
    suite: attributes?.suite || '',
    rackId: attributes?.rack || '',
    connectorType: isManagedDIA ? ConnectorType.RJ45 : (attributes?.connection_type as ConnectorType | null),
    mediaType: isManagedDIA ? MediaType.TX : (attributes?.media_type as MediaType | null),
    powerType: isManagedDIA ? PowerType.AC : ((attributes?.power || PowerType.AC) as PowerType),
    duplexity: (attributes?.duplexity as Duplexity | null) || Duplexity.Full,
    autoNegotiation: isManagedDIA
      ? true
      : attributes?.auto_negotiation !== null && attributes?.auto_negotiation !== undefined
      ? isYes(attributes?.auto_negotiation)
      : undefined,
    vlanTagging: isYes(attributes?.vlan_tagging),
    vlanId: isVlanIdInternallyAssigned(attributes?.vlan_id) ? VLAN_ID_INTERNALLY_ASSIGNED : attributes?.vlan_id || null,
    shadowVLANTagging: isYes(attributes?.shadow_vlan_tagging),
    shadowVLANId: isVlanIdInternallyAssigned(attributes?.shadow_vlan_id)
      ? VLAN_ID_INTERNALLY_ASSIGNED
      : attributes?.shadow_vlan_id,
    nni_job_reference: attributes?.nni_job_reference,
    nni_idn_number: attributes?.nni_idn_number,
    shadow_nni_job_reference: attributes?.shadow_nni_job_reference,
    shadow_nni_idn_number: attributes?.shadow_nni_idn_number,
    new_nni_requests: [],
    // new_nni_requests: attributes.new_nni_requests ? attributes.new_nni_requests.map(mapNewNNIRequestFromAPI) : [],
  };
}

function extractSiteConfigFromQuote(
  quote: IQuoteRecord | undefined,
  selectedPriceId: string | undefined,
  isManagedDIA: boolean | undefined,
  attributes: IOrderRecord['data']['attributes'],
  end: APISecondarySiteConfig['end']
) {
  if (!quote || !selectedPriceId) return undefined;

  const prices = filterIncludedBy<IQuotePricedOrOrdered>(quote?.included as IQuotePricedOrOrdered[], 'price');
  const primaryPrice = prices?.find((it) => it.id === selectedPriceId);
  const secondaryCircuits = primaryPrice?.attributes.secondary_circuits;
  if (!secondaryCircuits?.ro2_enabled) return undefined;

  const selectedCircuitId = secondaryCircuits.selected_circuit_id;
  const selectedCircuit = attributes.secondary_circuits_site_configs?.find(
    (it) => it.secondary_circuit_id === selectedCircuitId && it.end === end
  );

  return selectedCircuitId ? getSecondarySiteConfig(selectedCircuit, selectedCircuitId, end, isManagedDIA) : undefined;
}

const replaceLocationA = (attributes: IOrderRecord['data']['attributes'], quote?: IQuoteRecord): IOrderLocation => {
  const { meta } = attributes;
  const quoteData = quote?.data;
  const secondarySiteConfig = extractSiteConfigFromQuote(
    quote,
    quoteData?.attributes.selected_price?.id,
    quoteData?.attributes.is_managed_dia,
    attributes,
    'a'
  );

  let isAPIOnNet = false;

  if (quoteData) {
    isAPIOnNet =
      getOrigin(quoteData) === QuoteOrigin.API &&
      (quoteData.attributes.selected_price?.a_end_access_type === Supplier.NONE ||
        quoteData.attributes.selected_price?.a_end_access_type === Supplier.COLT);
  }

  return {
    locationData: {
      addressType: isAPIOnNet ? AddressType.ON_NET : attributes.a_end_full_site_address_type || AddressType.PAF,
      pafAddressNotListed: (meta && meta.a_end_PAF_address_not_listed) || false,
      fullAddressNotListed: (meta && meta.a_end_full_address_not_listed) || false,
      readonly_postcode: attributes.a_end_full_site_postal_code,
      pafAddress: {
        udprn: meta?.a_end_PAF_full_site_udprn || '',
        organisation_name: meta?.a_end_PAF_organisation_name || '',
        building_name: meta?.a_end_PAF_building_name || '',
        building_number: meta?.a_end_PAF_building_number || '',
        county: meta?.a_end_PAF_county || '',
        post_town: meta?.a_end_PAF_post_town || '',
        postcode: meta?.a_end_PAF_postcode || '',
        thoroughfare: meta?.a_end_PAF_thoroughfare || '',
        sub_building: meta?.a_end_PAF_sub_building || '',
      },
      fullAddress: {
        alk: attributes.a_end_full_site_alk || '',
        css_district_code: '',
        qualifier: attributes.a_end_full_site_match_type,
        building_name: attributes.a_end_full_site_building_name || '',
        building_number: attributes.a_end_full_site_building_number || '',
        county: attributes.a_end_full_site_county || '',
        post_town: attributes.a_end_full_site_town || '',
        postcode: attributes.a_end_full_site_postal_code || '',
        street: attributes.a_end_full_site_street || '',
        sub_building: attributes.a_end_full_site_sub_building || '',
      },
    },
    siteReadiness: setAEndSiteReadiness(attributes),
    endCompanyName: attributes.a_end_company_name || '',
    siteType: attributes.a_end_site_type || null,
    siteContact: setAEndSiteContact(attributes),
    siteConfig: getSiteConfig(attributes, quoteData?.attributes.is_managed_dia),
    secondarySiteConfig: secondarySiteConfig,
    siteCoords: {
      latitude: attributes.a_end_latitude || '',
      longitude: attributes.a_end_longitude || '',
      easting: attributes.a_end_easting || '',
      northing: attributes.a_end_northing || '',
    },
  };
};

const replaceLocationB = (attributes: IOrderRecord['data']['attributes'], quote?: IQuoteRecord): IOrderLocation => {
  const { meta } = attributes;
  const quoteData = quote?.data;
  const secondarySiteConfig = extractSiteConfigFromQuote(
    quote,
    quoteData?.attributes.selected_price?.id,
    quoteData?.attributes.is_managed_dia,
    attributes,
    'b'
  );

  let isAPIOnNet = false;

  if (quoteData) {
    isAPIOnNet =
      getOrigin(quoteData) === QuoteOrigin.API &&
      (quoteData.attributes.selected_price?.b_end_access_type === Supplier.NONE ||
        quoteData.attributes.selected_price?.b_end_access_type === Supplier.COLT);
  }

  return {
    locationData: {
      addressType: isAPIOnNet ? AddressType.ON_NET : attributes.b_end_full_site_address_type || AddressType.PAF,
      pafAddressNotListed: (meta && meta.b_end_PAF_address_not_listed) || false,
      fullAddressNotListed: (meta && meta.b_end_full_address_not_listed) || false,
      readonly_postcode: attributes.b_end_full_site_postal_code,
      pafAddress: {
        udprn: meta?.b_end_PAF_full_site_udprn || '',
        organisation_name: meta?.b_end_PAF_organisation_name || '',
        building_name: meta?.b_end_PAF_building_name || '',
        building_number: meta?.b_end_PAF_building_number || '',
        county: meta?.b_end_PAF_county || '',
        post_town: meta?.b_end_PAF_post_town || '',
        postcode: meta?.b_end_PAF_postcode || '',
        thoroughfare: meta?.b_end_PAF_thoroughfare || '',
        sub_building: meta?.b_end_PAF_sub_building || '',
      },
      fullAddress: {
        alk: attributes.b_end_full_site_alk || '',
        css_district_code: '',
        qualifier: attributes.b_end_full_site_match_type,
        building_name: attributes.b_end_full_site_building_name || '',
        building_number: attributes.b_end_full_site_building_number || '',
        county: attributes.b_end_full_site_county || '',
        post_town: attributes.b_end_full_site_town || '',
        postcode: attributes.b_end_full_site_postal_code || '',
        street: attributes.b_end_full_site_street || '',
        sub_building: attributes.b_end_full_site_sub_building || '',
      },
    },
    endCompanyName: attributes.b_end_company_name || '',
    siteType: attributes.b_end_site_type || null,
    siteContact: setBEndSiteContact(attributes),
    siteReadiness: setBEndSiteReadiness(attributes),
    siteConfig: {
      floor: attributes.b_end_floor || '',
      room: attributes.b_end_room || null,
      suite: attributes.b_end_suite || '',
      rackId: attributes.b_end_rack || '',
      connectorType: attributes.b_end_connector_type as ConnectorType,
      mediaType: (attributes.b_end_media_type as MediaType | null) || null,
      powerType: (attributes.b_end_power || PowerType.AC) as PowerType,
      duplexity: (attributes.b_end_duplexity as Duplexity) || null || Duplexity.Full,
      autoNegotiation:
        attributes.b_end_auto_negotiation !== null && attributes.b_end_auto_negotiation !== undefined
          ? isYes(attributes.b_end_auto_negotiation)
          : undefined,
      vlanTagging: isYes(attributes.b_end_vlan_tagging),
      vlanId: attributes.b_end_vlan_id || null,
      new_nni_requests: attributes.new_nni_requests ? attributes.new_nni_requests.map(mapNewNNIRequestFromAPI) : [],
    },
    secondarySiteConfig: secondarySiteConfig,
    siteCoords: {
      latitude: attributes.b_end_latitude || '',
      longitude: attributes.b_end_longitude || '',
      easting: attributes.b_end_easting || '',
      northing: attributes.b_end_northing || '',
    },
  };
};

const setContactAsSiteContact = (contact: IContact, siteContact: ISiteContact) => {
  contact.firstName = siteContact.firstName;
  (contact.surname = siteContact.surname), (contact.email = siteContact.email);
  contact.phoneNumber = siteContact.phone;
};

const replaceOrderState = (order: IOrderRecord, quote: IQuoteRecord) => {
  const { data } = order;
  const { data: quoteData } = quote;
  const { attributes } = data;
  const { attributes: quoteAttributes } = quoteData;

  // TODO: `deserialiseJsonApiResponse` expects a `Response` object, but `IOrderRecord` does not overlap sufficiently and triggers many errors.
  // Currently there are many loose types in all the reducers as no typing is being passed when the action is being triggered and all payloads have `any` types.
  // These typing inconsistencies must be addressed as soon as possible, for now we keep casting `order` as `Response` to avoid errors.
  const deserialised = deserialiseJsonApiResponse(order as Response);
  const messages = deserialised.selectMessages();

  const state: IOrderState = {
    ...initialState,
    id: data.id,
    orderPageLoadState: RequestLifecycle.Success,
    orderPageLoadError: undefined,
    order: {
      ...initialState.order,
      shortId: attributes.short_id,
      acceptedAt: attributes.accepted_at,
      acceptedBy: attributes.accepted_by,
      createdAt: attributes.created_at,
      quoteId: attributes.quote_id,
      updatedAt: attributes.updated_at,
      updatedBy: attributes.updated_by,
      placedAt: attributes.placed_at,
      placedBy: attributes.placed_by,
      submittedForReviewBy: attributes.submitted_for_review_by,
      submittedForReviewAt: attributes.submitted_for_review_at,
      submittedForCrfBy: attributes.submitted_for_crf_by,
      submittedForCrfAt: attributes.submitted_for_crf_at,
      downloadedCrfBy: attributes.downloaded_crf_by,
      downloadedCrfAt: attributes.downloaded_crf_at,
      serviceDeliveryContact: replaceDeliveryContact(attributes),

      ...setBillingContactAndInformation(order, quote),
      ...setLocationA(order, quote),
      ...setCloudConnect(order),
      ...setLocationB(order, quote),

      cerillion_basket_id: attributes.cerillion_basket_id || null,
      cerillion_status: attributes.cerillion_status || null,
      cerillion_stage: attributes.cerillion_stage || null,
      cerillion_stage_history: attributes.cerillion_stage_history || null,
      cerillion_order_status_message: attributes.cerillion_order_status_message || null,
      downstream_responses: deserialised.selectDownstreamResponses(),
      aEndGeaCablelinkId: attributes.a_end_gea_cablelink_id || null,
      bEndGeaCablelinkId: attributes.b_end_gea_cablelink_id || null,
      notes: replaceNotes(attributes, attributes.state_history),
      productType: attributes.product_type,
      state: attributes.state || OrderStatus.DRAFT,
      stateHistory: attributes.state_history || [],

      orderMeta: {
        ...setOrderMetaAEnd(order),
        ...setOrderMetaBEnd(order),
      },
      messages: transformMessagesForReducer(messages),
    },
  };

  return orderDefaults(state, quoteAttributes);
};

function getSecondaryIPDetails(attributes: IOrderDataAttributes): SecondaryIPContact | undefined {
  const secondaryIPInformation = attributes.secondary_circuits_ip_information?.find((it) => it.end === 'a');

  return secondaryIPInformation
    ? { ipText: secondaryIPInformation.ip_text, nicRIPEHandle: secondaryIPInformation.ip_contact_nic_ripe_handle }
    : undefined;
}

export const setBillingContactAndInformation = (order: IOrderRecord, quote: IQuoteRecord) => {
  const { data } = order;
  const { data: quoteData } = quote;
  const { attributes } = data;
  const { attributes: quoteAttributes } = quoteData;

  return {
    orderContact: replaceOrderContact(attributes),
    operationalContact: replaceOperationalContact(attributes),
    billingContactAndAddress: replaceBillingAndContact(attributes),
    billingFrequency: attributes.billing_frequency || null,
    ipContact: replaceIPContact(attributes),
    ipText: attributes.ip_text || '',
    secondaryIPDetails: getSecondaryIPDetails(attributes),
    wan_ip_in_addition:
      attributes.product_type === ProductType.DIA &&
      isStaticIPWanIPCompatible(quoteAttributes.ip_count) &&
      attributes.wan_ip_in_addition !== false
        ? true
        : attributes.wan_ip_in_addition,
  };
};

export const setLocationA = (order: IOrderRecord, quote: IQuoteRecord) => {
  const { data } = order;
  const { attributes } = data;

  return {
    locationA: {
      ...initialState.order.locationA,
      ...replaceLocationA(attributes, quote),
    },
  };
};

export const setLocationB = (order: IOrderRecord, quote: IQuoteRecord): Partial<IOrder> => {
  return {
    locationB: {
      ...initialState.order.locationB,
      ...replaceLocationB(order.data.attributes, quote),
    },
  };
};
export const setCloudConnect = (order: IOrderRecord) => {
  const {
    data: {
      attributes: { cloud_connect_configuration },
    },
  } = order;

  return {
    cloudConnect: {
      serviceKey1: cloud_connect_configuration?.keys?.primary || '',
      serviceKey2: cloud_connect_configuration?.keys?.secondary || '',
      microsoftPeering: cloud_connect_configuration?.microsoft_peering || '',
      privatePeering: cloud_connect_configuration?.private_peering || '',
    },
  };
};

export const setOrderMetaAEnd = (order: IOrderRecord) => {
  const {
    data: {
      attributes: { meta },
    },
  } = order;

  return {
    aEnd: {
      reference: meta?.a_end_on_net_reference || '',
      location: meta?.a_end_on_net_location || '',
      name: meta?.a_end_on_net_name || '',
    },
  };
};

export const setOrderMetaBEnd = (order: IOrderRecord) => {
  const {
    data: {
      attributes: { meta },
    },
  } = order;

  return {
    bEnd: {
      reference: meta?.b_end_on_net_reference || '',
      location: meta?.b_end_on_net_location || '',
      name: meta?.b_end_on_net_name || '',
    },
  };
};

export const setAEndSiteReadiness = (attributes: IOrderRecord['data']['attributes']) => ({
  accessNotice: attributes.a_end_access_required_prior_notice
    ? (attributes.a_end_access_required_prior_notice as AccessNotice)
    : AccessNotice.UpTo48Hours,
  moreThanOneTenant: isYes(attributes.a_end_more_than_one_tenant),
  asbestosRegister: replaceAsbestosRegister(attributes.product_type!, 'A', attributes.a_end_asbestos_register),
  hazardsOnSite: isYes(attributes.a_end_any_hazard),
  hazardsOnSiteDescription: attributes.a_end_hazard_description,
  landOwnerPermissionRequired: (attributes.meta && attributes.meta.a_end_land_owner_permission_required) || false,
});

export const setBEndSiteReadiness = (attributes: IOrderRecord['data']['attributes']) => ({
  accessNotice: attributes.b_end_access_required_prior_notice
    ? (attributes.b_end_access_required_prior_notice as AccessNotice)
    : AccessNotice.UpTo48Hours,
  moreThanOneTenant: isYes(attributes.b_end_more_than_one_tenant),
  asbestosRegister: replaceAsbestosRegister(attributes.product_type!, 'B', attributes.b_end_asbestos_register),
  hazardsOnSite: isYes(attributes.b_end_any_hazard),
  hazardsOnSiteDescription: attributes.b_end_hazard_description,
  landOwnerPermissionRequired: (attributes.meta && attributes.meta.b_end_land_owner_permission_required) || false,
});

export const setAEndSiteContact = (attributes: IOrderRecord['data']['attributes']) => {
  const convertedName = legacyAwareNameTransform({
    legacyName: attributes.a_end_site_contact_name,
    firstName: attributes.a_end_site_contact_first_name,
    surname: attributes.a_end_site_contact_surname,
  });

  return {
    email: attributes.a_end_site_contact_email || '',
    firstName: convertedName.firstName,
    surname: convertedName.surname,
    notes: attributes.a_end_site_notes || '',
    phone: attributes.a_end_site_contact_telephone || '',
  };
};

export const setBEndSiteContact = (attributes: IOrderRecord['data']['attributes']) => {
  const convertedName = legacyAwareNameTransform({
    legacyName: attributes.b_end_site_contact_name,
    firstName: attributes.b_end_site_contact_first_name,
    surname: attributes.b_end_site_contact_surname,
  });

  return {
    email: attributes.b_end_site_contact_email || '',
    firstName: convertedName.firstName,
    surname: convertedName.surname,
    notes: attributes.b_end_site_notes || '',
    phone: attributes.b_end_site_contact_telephone || '',
  };
};

const defaultSiteReadiness = {
  accessNotice: AccessNotice.UpTo48Hours,
  asbestosRegister: AsbestosRegister.NotBuiltPriorTo2000,
  moreThanOneTenant: false,
  hazardsOnSite: false,
  landOwnerPermissionRequired: false,
};

const defaultSiteConfig: ISiteConfig = {
  floor: '',
  room: null,
  suite: '',
  rackId: '',
  connectorType: null,
  mediaType: null,
  powerType: null,
  duplexity: Duplexity.Full,
  autoNegotiation: true,
  vlanTagging: false,
  vlanId: null,
  new_nni_requests: [],
};

export const initialState: IOrderState = {
  creating: {},
  updating: {},
  order: {
    shortId: '',
    orderMeta: {
      aEnd: {
        reference: '',
        location: '',
        name: '',
      },
      bEnd: {
        reference: '',
        location: '',
        name: '',
      },
    },
    createdAt: '',
    locationA: {
      locationData: {
        pafAddress: {
          udprn: '',
          building_name: '',
          building_number: '',
          county: '',
          post_town: '',
          postcode: '',
          thoroughfare: '',
          sub_building: '',
        },
        addressType: AddressType.PAF,
        fullAddress: {
          alk: '',
          css_district_code: '',
          qualifier: null,
          building_name: '',
          building_number: '',
          county: '',
          post_town: '',
          postcode: '',
          street: '',
          sub_building: '',
        },
        readonly_postcode: '',
        pafAddressNotListed: false,
        fullAddressNotListed: false,
      },
      endCompanyName: '',
      siteType: null,
      siteConfig: defaultSiteConfig,
      siteContact: {
        firstName: '',
        surname: '',
        phone: '',
        email: '',
        notes: '',
      },
      siteReadiness: defaultSiteReadiness,
      siteCoords: {
        latitude: '',
        longitude: '',
        easting: '',
        northing: '',
      },
    },
    locationB: {
      locationData: {
        pafAddress: {
          udprn: '',
          building_name: '',
          building_number: '',
          county: '',
          post_town: '',
          postcode: '',
          thoroughfare: '',
          sub_building: '',
        },
        addressType: AddressType.PAF,
        fullAddress: {
          alk: '',
          css_district_code: '',
          qualifier: null,
          building_name: '',
          building_number: '',
          county: '',
          post_town: '',
          postcode: '',
          street: '',
          sub_building: '',
        },
        readonly_postcode: '',
        pafAddressNotListed: false,
        fullAddressNotListed: false,
      },
      endCompanyName: '',
      siteType: null,
      siteConfig: defaultSiteConfig,
      siteContact: {
        firstName: '',
        surname: '',
        phone: '',
        email: '',
        notes: '',
      },
      siteReadiness: defaultSiteReadiness,
      siteCoords: {
        latitude: '',
        longitude: '',
        easting: '',
        northing: '',
      },
    },
    billingContactAndAddress: {
      address: '',
      email: '',
      firstName: '',
      surname: '',
      phoneNumber: '',
      purchaseOrderRef: '',
    },
    orderContact: {
      firstName: '',
      surname: '',
      phoneNumber: '',
      email: '',
    },
    serviceDeliveryContact: {
      firstName: '',
      surname: '',
      phoneNumber: '',
      email: '',
    },
    ipContact: {
      address: '',
      email: '',
      firstName: '',
      surname: '',
      nic_ripe_handle: '',
      organisation: '',
      telephone: '',
    },
    cloudConnect: {
      serviceKey1: '',
      serviceKey2: '',
      microsoftPeering: '',
      privatePeering: '',
    },
    ipText: '',
    secondaryIPDetails: undefined,
    wan_ip_in_addition: null,
    cerillion_basket_id: null,
    cerillion_status: null,
    cerillion_order_status_message: null,
    cerillion_stage_history: null,
    downstream_responses: [],
    operationalContact: {
      firstName: '',
      surname: '',
      phoneNumber: '',
      email: '',
    },
    notes: [],
    productType: null,
    state: OrderStatus.DRAFT,
    stateHistory: [],
    aEndGeaCablelinkId: null,
    bEndGeaCablelinkId: null,
    billingFrequency: null,
    messages: [],
  },
  orderPageLoadState: RequestLifecycle.Loading,
  retrieving: {
    inProgress: false,
  },
  createNoteState: null,
  submitting: {},
  editMode: false,
};

const reducer = produce((draft: IOrderState, action: OrderAction): void | IOrderState => {
  const { order, creating, updating } = draft;
  const { locationA, locationB } = order;

  switch (action.type) {
    case OrderActionTypes.SET_A_END_ADDRESS:
      if (action.payload.aEndAddress !== null) {
        locationA.locationData.fullAddress = action.payload.aEndAddress.attributes;
      } else {
        locationA.locationData.fullAddress = initialState.order.locationA.locationData.fullAddress;
      }
      break;

    case OrderActionTypes.SET_A_END_PAF_ADDRESS:
      if (action.payload.aEndAddress !== null) {
        locationA.locationData.pafAddress = action.payload.aEndAddress.attributes;

        locationA.locationData.fullAddress = initialState.order.locationA.locationData.fullAddress;
      } else {
        locationA.locationData.pafAddress = initialState.order.locationA.locationData.pafAddress;
      }
      break;

    case OrderActionTypes.SET_B_END_ADDRESS:
      if (action.payload.bEndAddress !== null) {
        locationB.locationData.fullAddress = action.payload.bEndAddress.attributes;
      } else {
        locationB.locationData.fullAddress = initialState.order.locationB.locationData.fullAddress;
      }
      break;

    case OrderActionTypes.SET_B_END_PAF_ADDRESS:
      if (action.payload.bEndAddress !== null) {
        locationB.locationData.pafAddress = action.payload.bEndAddress.attributes;

        locationB.locationData.fullAddress = initialState.order.locationB.locationData.fullAddress;
      } else {
        locationB.locationData.pafAddress = initialState.order.locationB.locationData.pafAddress;
      }
      break;

    case OrderActionTypes.SET_A_END_ADDRESS_NOT_LISTED:
      locationA.locationData.pafAddressNotListed = action.payload.notListed;
      if (action.payload.notListed) {
        locationA.locationData.fullAddress = initialState.order.locationA.locationData.fullAddress;
      }
      break;

    case OrderActionTypes.SET_A_END_FULL_ADDRESS_NOT_LISTED:
      locationA.locationData.fullAddressNotListed = action.payload.notListed;
      if (action.payload.notListed) {
        locationA.locationData.fullAddress = initialState.order.locationA.locationData.fullAddress;
      }
      break;

    case OrderActionTypes.SET_B_END_ADDRESS_NOT_LISTED:
      locationB.locationData.pafAddressNotListed = action.payload.notListed;
      if (action.payload.notListed) {
        locationB.locationData.fullAddress = initialState.order.locationB.locationData.fullAddress;
      }
      break;

    case OrderActionTypes.SET_B_END_FULL_ADDRESS_NOT_LISTED:
      locationB.locationData.fullAddressNotListed = action.payload.notListed;
      if (action.payload.notListed) {
        locationB.locationData.fullAddress = initialState.order.locationB.locationData.fullAddress;
      }
      break;

    case OrderActionTypes.CREATE_ORDER_STARTED:
      creating.inProgress = true;
      creating.error = false;
      break;

    case OrderActionTypes.CREATE_ORDER_ENDED:
      creating.inProgress = false;
      break;

    case OrderActionTypes.CREATE_ORDER_ERROR:
      creating.error = true;
      break;

    case OrderActionTypes.UPDATE_ORDER_STARTED:
      updating.inProgress = true;
      updating.error = false;
      break;

    case OrderActionTypes.UPDATE_ORDER_ENDED:
      updating.inProgress = false;
      break;

    case OrderActionTypes.UPDATE_ORDER_ERROR:
      updating.error = true;
      break;

    case OrderActionTypes.SET_ORDER_ID:
      draft.id = action.payload.id;
      break;

    case OrderActionTypes.SET_A_END_COMPANY_NAME:
      locationA.endCompanyName = action.payload.endCompanyName;
      break;

    case OrderActionTypes.SET_SITE_TYPE: {
      const { end, siteType } = action.payload;

      if (end === 'A') {
        locationA.siteType = siteType;
      } else {
        locationB.siteType = siteType;
      }

      break;
    }

    case OrderActionTypes.SET_SITE_COORDS: {
      const payload = action.payload as SetSiteCoords['payload'];
      const { end, field, value } = payload;

      draft.order[end === 'A' ? 'locationA' : 'locationB'].siteCoords[field] = value.trim();

      break;
    }

    case OrderActionTypes.SET_B_END_COMPANY_NAME:
      locationB.endCompanyName = action.payload.endCompanyName;
      break;

    case OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_STARTED:
      draft.orderPageLoadError = undefined;
      draft.orderPageLoadState = RequestLifecycle.Loading;
      break;

    case OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_ERROR:
      draft.orderPageLoadState = RequestLifecycle.Error;
      draft.orderPageLoadError = action.payload.error;
      break;

    case OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_SUCCEEDED:
      draft.orderPageLoadState = RequestLifecycle.Success;
      break;

    case OrderActionTypes.UPDATE_ADDRESS_FIELD_LOCATION_A:
      (locationA.locationData.fullAddress as any)[action.payload.fieldName] = action.payload.fieldValue;
      break;

    case OrderActionTypes.UPDATE_ADDRESS_FIELD_LOCATION_B:
      (locationB.locationData.fullAddress as any)[action.payload.fieldName] = action.payload.fieldValue;
      break;

    case OrderActionTypes.UPDATE_ON_NET_ADDRESS_FIELD: {
      if (action.payload.attributes !== null) {
        draft.order.orderMeta[action.payload.end === 'A' ? 'aEnd' : 'bEnd'] = {
          ...action.payload.attributes,
        };
      } else {
        draft.order.orderMeta[action.payload.end === 'A' ? 'aEnd' : 'bEnd'] = {
          reference: '',
          name: '',
          location: '',
        };
      }

      break;
    }

    case OrderActionTypes.REPLACE_ORDER_A_END_LOCATION: {
      const { data } = action.payload.order;
      const { attributes } = data;

      order.updatedAt = attributes.updated_at;

      order.locationA = {
        ...replaceLocationA(attributes),
        secondarySiteConfig: order.locationA.secondarySiteConfig,
      };
      break;
    }

    case OrderActionTypes.REPLACE_ORDER_B_END_LOCATION: {
      const { data } = action.payload.order;
      const { attributes } = data;

      order.updatedAt = attributes.updated_at;

      order.locationB = {
        ...replaceLocationB(attributes),
        secondarySiteConfig: order.locationB.secondarySiteConfig,
      };
      break;
    }

    case OrderActionTypes.REPLACE_ORDER_BILLING_AND_CONTACT: {
      const { data } = action.payload.order;
      const { attributes } = data;

      order.updatedAt = attributes.updated_at;
      order.billingContactAndAddress = replaceBillingAndContact(attributes);
      order.orderContact = replaceOrderContact(attributes);
      order.serviceDeliveryContact = replaceDeliveryContact(attributes);
      order.operationalContact = replaceOperationalContact(attributes);
      order.ipContact = replaceIPContact(attributes);
      break;
    }

    case OrderActionTypes.REPLACE_ORDER_STATUS: {
      const { status } = action.payload;
      order.state = status;
      break;
    }

    case OrderActionTypes.REPLACE_ORDER_HISTORY: {
      const { history } = action.payload;
      order.stateHistory = history;
      break;
    }

    case OrderActionTypes.CREATE_NOTE: {
      draft.createNoteState = RequestLifecycle.Loading;
      break;
    }

    case OrderActionTypes.CREATE_NOTE_SUCCESS: {
      draft.createNoteState = RequestLifecycle.Success;
      break;
    }

    case OrderActionTypes.CREATE_NOTE_ERROR: {
      draft.createNoteState = RequestLifecycle.Error;
      break;
    }

    case OrderActionTypes.CREATE_NOTE_RESET_STATE: {
      draft.createNoteState = null;
      break;
    }

    case OrderActionTypes.REPLACE_ORDER_NOTES: {
      const { data } = action.payload.order;
      const { attributes } = data;

      order.updatedAt = attributes.updated_at;
      order.notes = replaceNotes(attributes, data.attributes.state_history);
      break;
    }

    // TODO: add different method here to replace the state for separate sections
    case OrderActionTypes.REPLACE_ORDER_STATE: {
      const { order: orderRecord, quote: quoteRecord } = action.payload;

      return replaceOrderState(orderRecord, quoteRecord);
    }

    case OrderActionTypes.EDIT_ORDER_STATE:
      draft.order = action.payload;
      draft.editMode = true;
      break;

    case OrderActionTypes.UPDATE_BILLING_AND_CONTACT:
      order.billingContactAndAddress = action.payload.billingContactAndAddress;
      break;

    case OrderActionTypes.UPDATE_IP_CONTACT:
      order.ipContact = action.payload.ipContact;
      break;

    case OrderActionTypes.UPDATE_IP_TEXT:
      order.ipText = action.payload.text;
      break;

    case OrderActionTypes.UPDATE_SECONDARY_IP_DETAILS:
      order.secondaryIPDetails = {
        ipText: action.payload.ipText ?? order.secondaryIPDetails?.ipText ?? '',
        nicRIPEHandle: action.payload.nicRipeHandle ?? order.secondaryIPDetails?.nicRIPEHandle ?? '',
      };
      break;

    case OrderActionTypes.UPDATE_WAN_IP_IN_ADDITION:
      order.wan_ip_in_addition = action.payload.wan_ip_in_addition;
      break;

    case OrderActionTypes.UPDATE_BILLING_FREQUENCY:
      order.billingFrequency = action.payload.billingFrequency;
      break;

    case OrderActionTypes.UPDATE_CLOUD_CONNECT:
      order.cloudConnect = action.payload;
      break;

    case OrderActionTypes.UPDATE_CONTACT:
      switch (action.payload.contactIdentifier) {
        case ContactIdentifierType.orderContact:
          switch (action.payload.contactType) {
            case 'aEndContact':
              setContactAsSiteContact(order.orderContact, action.payload.order.locationA.siteContact);
              break;
            case 'bEndContact':
              setContactAsSiteContact(order.orderContact, action.payload.order.locationB.siteContact);
              break;
            case 'operationalContact':
              order.orderContact = cloneObject(action.payload.order.operationalContact);
              break;
            case 'ipContact':
              draft.order.orderContact = {
                ...draft.order.orderContact,
                firstName: draft.order.ipContact.firstName,
                surname: draft.order.ipContact.surname,
                phoneNumber: draft.order.ipContact.telephone,
                email: draft.order.ipContact.email,
              };
              break;
            default:
              break;
          }
          break;
        case ContactIdentifierType.operationalContact:
          switch (action.payload.contactType) {
            case 'aEndContact':
              setContactAsSiteContact(order.operationalContact, action.payload.order.locationA.siteContact);
              break;
            case 'bEndContact':
              setContactAsSiteContact(order.operationalContact, action.payload.order.locationB.siteContact);
              break;
            case 'orderContact':
              order.operationalContact = cloneObject(action.payload.order.orderContact);
              break;
            case 'ipContact':
              draft.order.operationalContact = {
                ...draft.order.operationalContact,
                firstName: draft.order.ipContact.firstName,
                surname: draft.order.ipContact.surname,
                phoneNumber: draft.order.ipContact.telephone,
                email: draft.order.ipContact.email,
              };
              break;
            default:
              break;
          }
          break;

        case ContactIdentifierType.ipContact:
          switch (action.payload.contactType) {
            case 'orderContact':
              draft.order.ipContact = {
                ...draft.order.ipContact,
                firstName: draft.order.orderContact.firstName,
                surname: draft.order.orderContact.surname,
                email: draft.order.orderContact.email,
                telephone: draft.order.orderContact.phoneNumber,
              };
              break;

            case 'operationalContact':
              draft.order.ipContact = {
                ...draft.order.ipContact,
                firstName: draft.order.operationalContact.firstName,
                surname: draft.order.operationalContact.surname,
                email: draft.order.operationalContact.email,
                telephone: draft.order.operationalContact.phoneNumber,
              };
              break;

            case 'aEndContact':
              draft.order.ipContact = {
                ...draft.order.ipContact,
                firstName: draft.order.locationA.siteContact.firstName,
                surname: draft.order.locationA.siteContact.surname,
                email: draft.order.locationA.siteContact.email,
                telephone: draft.order.locationA.siteContact.phone,
              };
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }
      break;

    case OrderActionTypes.SUBMIT_ORDER_STARTED: {
      draft.submitting.inProgress = true;
      draft.submitting.error = false;
      break;
    }

    case OrderActionTypes.SUBMIT_ORDER_SUCCESS: {
      draft.submitting.inProgress = false;
      break;
    }

    case OrderActionTypes.SUBMIT_ORDER_ERROR: {
      draft.submitting.inProgress = false;
      draft.submitting.error = true;
      break;
    }

    case OrderActionTypes.RESET_ORDER_STATE:
      return initialState;

    case OrderActionTypes.SET_A_END_ADDRESS_TYPE: {
      const { endAddressType } = action.payload;
      draft.order.locationA.locationData.addressType = endAddressType;
      break;
    }

    case OrderActionTypes.SET_B_END_ADDRESS_TYPE: {
      const { endAddressType } = action.payload;
      draft.order.locationB.locationData.addressType = endAddressType;
      break;
    }

    case OrderActionTypes.NEW_ORDER_MESSAGE: {
      draft.order.messages.push(action.payload.message);
      break;
    }

    case OrderActionTypes.MULTI_QUOTE_COPY_SECTION: {
      const { orderToCopyFrom, quoteToCopyFrom, targetSection } = action.payload as IMultiQuoteCopySectionPayload;

      switch (targetSection) {
        case 'a-end': {
          draft.order = {
            ...draft.order,
            locationA: {
              ...draft.order.locationA,
              siteContact: setAEndSiteContact(orderToCopyFrom.data.attributes),
              siteReadiness: setAEndSiteReadiness(orderToCopyFrom.data.attributes),
            },
          };
          break;
        }

        case 'b-end': {
          draft.order = {
            ...draft.order,
            locationB: {
              ...draft.order.locationB,
              siteContact: setBEndSiteContact(orderToCopyFrom.data.attributes),
              siteReadiness: setBEndSiteReadiness(orderToCopyFrom.data.attributes),
            },
          };
          break;
        }

        case 'billing-and-contact-info': {
          draft.order = {
            ...draft.order,
            ...setBillingContactAndInformation(orderToCopyFrom, quoteToCopyFrom),
          };
          break;
        }

        default:
          break;
      }

      break;
    }

    case OrderActionTypes.COPY_FROM_ORDER_TEMPLATE: {
      const sourceOrderTemplate: IOrderTemplateState = action.payload.sourceOrderTemplate;

      draft.order = {
        ...draft.order,

        // TODO: disabled because fields still need refinement, see:
        // https://techcitylabs.slack.com/archives/CL36BNXSQ/p1704366182875459?thread_ts=1704364008.614659&cid=CL36BNXSQ

        // locationA: {
        //   ...draft.order.locationA,
        //   siteConfig: {
        //     ...draft.order.locationA.siteConfig,
        //     floor: sourceOrderTemplate.locationA.siteConfig.floor,
        //     room: sourceOrderTemplate.locationA.siteConfig.room,
        //     suite: sourceOrderTemplate.locationA.siteConfig.suite,
        //     rackId: sourceOrderTemplate.locationA.siteConfig.rackId,
        //     connectorType: sourceOrderTemplate.locationA.siteConfig.connectorType,
        //     mediaType: sourceOrderTemplate.locationA.siteConfig.mediaType,
        //     powerType: sourceOrderTemplate.locationA.siteConfig.powerType,
        //     duplexity: sourceOrderTemplate.locationA.siteConfig.duplexity,
        //     autoNegotiation: sourceOrderTemplate.locationA.siteConfig.autoNegotiation,
        //   },
        //   siteContact: sourceOrderTemplate.locationA.siteContact,
        //   siteReadiness: sourceOrderTemplate.locationA.siteReadiness,
        // },
        // locationB: {
        //   ...draft.order.locationB,
        //   siteConfig: {
        //     ...draft.order.locationB.siteConfig,
        //     floor: sourceOrderTemplate.locationB.siteConfig.floor,
        //     room: sourceOrderTemplate.locationB.siteConfig.room,
        //     suite: sourceOrderTemplate.locationB.siteConfig.suite,
        //     rackId: sourceOrderTemplate.locationB.siteConfig.rackId,
        //     connectorType: sourceOrderTemplate.locationB.siteConfig.connectorType,
        //     mediaType: sourceOrderTemplate.locationB.siteConfig.mediaType,
        //     powerType: sourceOrderTemplate.locationB.siteConfig.powerType,
        //     duplexity: sourceOrderTemplate.locationB.siteConfig.duplexity,
        //     autoNegotiation: sourceOrderTemplate.locationB.siteConfig.autoNegotiation,
        //   },
        //   siteContact: sourceOrderTemplate.locationB.siteContact,
        //   siteReadiness: sourceOrderTemplate.locationB.siteReadiness,
        // },

        orderContact: sourceOrderTemplate.orderContact,
        operationalContact: sourceOrderTemplate.operationalContact,
        billingContactAndAddress: sourceOrderTemplate.billingContactAndAddress,
        billingFrequency: sourceOrderTemplate.billingFrequency,
        ipContact: sourceOrderTemplate.ip.contact,
        ipText: sourceOrderTemplate.ip.justification,
      };
      break;
    }

    case OrderActionTypes.SET_SELECTED_ORDER_TEMPLATE: {
      const { selectedOrderTemplate } = action.payload;
      draft.order.selectedOrderTemplate = selectedOrderTemplate;
      break;
    }

    default:
      return draft;
  }
}, initialState);

export default reducer;
