import {
  ALKLocation,
  IOpenReachAddress,
  IOpenReachAddressSite,
  IPAFAddressSite,
  IPostcodeSite,
  IPostcodeSiteBase,
  Qualifier,
} from 'shared/types/postcodeResults';
import { IOnNetSite } from 'shared/types/onNetSite';
import { AddressType, INNI, IPriceData, IQuote } from 'Quotes/types/store';
import { IOrderMetaForLocation } from 'Order/types/store';
import { getSelectedShadowNNIReference } from 'Quotes/QuoteBuilder/utils/getSelectedNNIReference';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { INNIRecord } from 'Location/NNI/types';
import { buildNameThatMightIncludeEmptyStrings } from '../buildNameThatMightIncludeEmptyStrings';
import { bearerForDisplay } from '../connectionCapacity';
import { compact, isEmpty, omit, values } from 'lodash';
import { getSelectedSecondaryPriceFromPrice, useSecondaryCircuits } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';

export const isManualAddress = (address: IOpenReachAddress, addressType: AddressType): boolean => {
  return ![AddressType.ON_NET, AddressType.ONAT].includes(addressType) && address.alk === '' && !address.qualifier;
};

export const concatenatePAFAddress = (fullAddress: IPAFAddressSite['attributes'], includePostcode = true): string => {
  return [
    fullAddress.organisation_name,
    fullAddress.building_name,
    fullAddress.sub_building,
    fullAddress.building_number,
    fullAddress.thoroughfare,
    fullAddress.post_town,
    fullAddress.county,
    includePostcode ? fullAddress.postcode : undefined,
  ]
    .filter((item) => !!item)
    .join(', ');
};

export const concatenateOpenReachAddress = (
  fullAddress: IPostcodeSite['attributes'] | ALKLocation['attributes'],
  withALK = false
): string => {
  return [
    fullAddress.building_name,
    fullAddress.sub_building,
    fullAddress.building_number,
    fullAddress.street,
    fullAddress.post_town,
    fullAddress.county,
    fullAddress.postcode,
    withALK ? fullAddress.alk : undefined,
  ]
    .filter((item) => !!item)
    .join(', ');
};

export const findOpenReachAddressByItsLabel = (
  label: string,
  addresses: IOpenReachAddressSite[]
): IOpenReachAddressSite | undefined => {
  return addresses.find((address) => {
    const concatinatedAddress = concatenateOpenReachAddress(address.attributes, true);
    return concatinatedAddress === label;
  });
};

export const findOnNetAddressByItsLabel = (label: string, addresses: IOnNetSite[]): IOnNetSite | undefined => {
  return addresses.find((address) => label === concatenateOnNetAddress(address.attributes));
};

export const findOnNetAddressByReference = (
  addressReference: string,
  addresses: IOnNetSite[]
): IOnNetSite | undefined => {
  return addresses.find((address) => {
    return addressReference === address.attributes.reference;
  });
};

export const concatenateOnNetAddress = (
  fullAddress: IOnNetSite['attributes'] | IOrderMetaForLocation,
  site?: IPostcodeSiteBase
): string => {
  return [site?.building_number, site?.building_name, fullAddress.location, fullAddress.name]
    .filter((item) => !!item)
    .join(', ');
};

export const findAddressByUdprn = (udprn: string, addresses: IPAFAddressSite[]): IPAFAddressSite | undefined => {
  return addresses.find((address) => address.attributes.udprn === udprn);
};

export function concatenateNNIBase(
  nni: INNI,
  dataCentreReference: string | undefined,
  quote: IQuote,
  price: IPriceData,
  meta?: IMeta
) {
  if (nni.selectedId && dataCentreReference) {
    return dataCentreReference;
  }

  if (nni.reference && nni.popAddress) {
    return [bearerForDisplay(quote.aEndPort), nni.reference, nni.popAddress].join(', ');
  }

  if (meta?.nni_label) {
    return meta.nni_label;
  }

  return (
    buildNameThatMightIncludeEmptyStrings([
      bearerForDisplay(quote.aEndPort),
      nni.reference || '',
      price.a_end_p_o_p_address || '',
    ]) || 'Unavailable'
  );
}

export const concatenateNNI = (meta: IMeta, quote: IQuote, price: IPriceData): string => {
  const nni = quote.location.aEnd.nni;
  const dataCentreReference = quote.location.aEnd.dataCentreReference;
  return concatenateNNIBase(nni, dataCentreReference, quote, price, meta);
};

export const getNNILabel = (nni: INNIRecord) => {
  return buildNameThatMightIncludeEmptyStrings([
    bearerForDisplay(nni.attributes.port_type),
    nni.attributes.reference,
    nni.attributes.pop_address,
  ]);
};

export const getINNILabel = (nni: INNI) => {
  return buildNameThatMightIncludeEmptyStrings([bearerForDisplay(nni.portType), nni.reference, nni.popAddress]);
};

export const concatenateShadowVlan = (meta: IMeta, nni: INNI): string => {
  const shadowRef = getSelectedShadowNNIReference(meta);
  return [shadowRef, nni?.shadowVLAN.name, nni?.shadowVLAN.location].filter((item) => !!item).join(', ');
};

export const isFTTXCompatibleOpenreachLocation = (
  location?: IOpenReachAddressSite | ALKLocation | null
): location is IOpenReachAddressSite | ALKLocation => {
  return !!location && !!location.attributes && !!location.attributes.alk && !!location.attributes.css_district_code;
};

export const openreachGoldFilter = (results: IOpenReachAddressSite[]) => {
  return results.filter((result) => result.attributes.qualifier === Qualifier.GOLD);
};

export const isPAFAddressEmpty = (address: IPAFAddressSite): boolean => {
  return isEmpty(compact(values(omit(address.attributes, 'postcode'))));
};

export const getSecondaryNNILabel = (quote: IQuote, selectedPrice: IPriceData) => {

  // Secondary Circuits
  const secondarySelectedPrice = getSelectedSecondaryPriceFromPrice(selectedPrice);
  const secondaryCircuits = useSecondaryCircuits(selectedPrice);

  // Secondary NNI Data
  const secondaryNniId = secondaryCircuits.selectedCircuit?.nniId;
  const nniData = secondaryCircuits.circuitData?.aEnd;
  const selectedSecondaryNNI =
    secondaryNniId && nniData && 'findNNI' in nniData ? nniData.findNNI(secondaryNniId) : undefined;

  // if there is no secondaryAEndSelectedNniId this is a new NNI request
  const secondaryAEndNNIReference = selectedSecondaryNNI?.reference;
  const secondaryAEndNNIPopAddress = selectedSecondaryNNI?.popAddress;
  const secondaryAEndPopAddress = secondarySelectedPrice?.a_end_p_o_p_address;

  const bearer = selectedSecondaryNNI?.capacity?.ports?.[0] ?? quote!.bearer;

  if (secondaryAEndNNIReference && secondaryAEndNNIPopAddress) {
    return `${bearerForDisplay(bearer)}, ${secondaryAEndNNIReference}, ${secondaryAEndNNIPopAddress}`;
  }
  
  return (
    buildNameThatMightIncludeEmptyStrings([
      bearerForDisplay(bearer),
      secondaryAEndNNIReference || '',
      secondaryAEndPopAddress || '',
    ]) || 'Unavailable'
  );
};