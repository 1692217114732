import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reducers';
import { submitOrder } from 'Order/actions';
import { formatDateTimeHuman } from 'shared/utils/dateHelper';
import Column from 'shared/components/atoms/Column';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import Alert from 'shared/components/atoms/Alert';
import Modal from 'shared/components/molecules/Modal';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import { usePreviousState } from 'shared/utils/customHooks';
import { ordersList } from 'Order/routes';
import { IOrder } from 'Order/types/store';
import { OrderStatus } from 'Order/types/orderRecord';
import { ICardState, MDIAText } from '../OrderReadyCard';
import { isCardStateReady } from '../shared/utils/isOrderReady';
import { OrderSubmitType } from 'Order/crud/submitOrder';
import TermsText from '../Terms/components/TermsText';
import { ProductType } from 'Quotes/types/productTypes';
import CancelOrder from '../CancelOrder';
import PresentForRoles from 'Auth/PresentForRoles';
import { userPermissions } from 'shared/utils/permissions';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';
import DownloadOrder from '../QuoteSummary/DownloadOrder';

type Props = {
  cardState: ICardState;
  productType: ProductType;
  showGeaCablelinkNotice: boolean;
  showOpenreachCPENotice: boolean;
  showAdditionalOpenreachSurchargeNotice: boolean;
  className?: string;
  isManagedDIA?: boolean;
};

const isOrderEditedBySSE = (order: IOrder) =>
  order.stateHistory.slice(-2).every((history) => history.state === OrderStatus.IN_REVIEW);

export const SubmittedSSE: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const navigate = useNavigate();
  return <SubmittedSSEInternal {...props} navigate={navigate} />;
};
export const SubmittedSSEInternal: FunctionComponent<React.PropsWithChildren<Props & Navigation>> = ({
  cardState,
  showGeaCablelinkNotice,
  showOpenreachCPENotice,
  showAdditionalOpenreachSurchargeNotice,
  productType,
  className,
  isManagedDIA,
  navigate,
}) => {
  const dispatch = useDispatch();
  const { id: orderId, order, submitting } = useSelector((state: IAppState) => state.orderBuilder);

  const prevInProgress = usePreviousState(submitting.inProgress);
  const goToOrders = () => navigate(ordersList);
  const [submitType, setSubmitType] = useState<OrderSubmitType>(OrderSubmitType.RETURN);
  const isReady = isCardStateReady(cardState, order.productType!);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCrfPlaceOrderModal, setShowCrfPlaceOrderModal] = useState(false);

  return (
    <div className={`${className} place-order__wrapper`}>
      <div className="row no-gutters">
        {order.state !== OrderStatus.ORDER_FORM_REQUESTED && (
          <Column>
            <p className="submitted-by-text">
              Submitted
              {order.submittedForReviewBy && <span> by {order.submittedForReviewBy}</span>} on:{' '}
              {formatDateTimeHuman(order.submittedForReviewAt!)} 
            </p>
            <p>
              This order has been submitted for review. Please check pricing and provisioning information, and make any
              required amends or notes. Choosing &apos;Place Order&apos; will place the order. &apos;Send back&apos; will
              return the order to a &apos;Submit for review&apos; state.
            </p>
            {isManagedDIA && <MDIAText />}
          </Column>
        )}
        {order.state === OrderStatus.ORDER_FORM_REQUESTED && (
          <Column>
            {order.submittedForCrfBy && (
              <p className="submitted-by-text">
                Order form requested
                <span> by {order.submittedForCrfBy}</span> on:{' '}
                {formatDateTimeHuman(order.submittedForCrfAt!)} 
              </p>
            )}
            {order.downloadedCrfBy && (
              <p className="submitted-by-text">
                Order form downloaded
                <span> by {order.downloadedCrfBy}</span> on:{' '}
                {formatDateTimeHuman(order.downloadedCrfAt!)} 
              </p>
            )}
            <p>
              An Order form request has been placed for this order. Please check pricing and provisioning information, and make any
              required amends or notes. Choosing &apos;Download customer request form&apos; will download the order in a prefilled PDF. 
              &apos;Send back&apos; will return the order to a &apos;Submit for review&apos; state.
            </p>
            {isManagedDIA && <MDIAText />}
          </Column>
        )}
      </div>

      {order.state !== OrderStatus.ORDER_FORM_REQUESTED && (
        <TermsText
          productType={productType}
          showGeaCablelinkNotice={showGeaCablelinkNotice}
          showOpenreachCPENotice={showOpenreachCPENotice}
          showAdditionalOpenreachSurchargeNotice={showAdditionalOpenreachSurchargeNotice}
          orderState={order.state}
        />
      )}

      <hr />

      {order.state === OrderStatus.ORDER_FORM_REQUESTED ? (
        <>
          <PresentForRoles roles={userPermissions.downloadOrderForm}>
            <DownloadOrder className="primaryRectangular larger-btn mr-4" 
              buttonText='Download order form' type='crf' />
          </PresentForRoles>
          <Button
            disabled={submitting.inProgress || isOrderEditedBySSE(order) || !isReady}
            className="accept-btn larger-btn mr-4"
            onClick={() => { setShowCrfPlaceOrderModal(true); }}
            mainStyle={ButtonMainStyle.PrimaryRectangular}
          >
            Accepted order form
          </Button>
        </>     
      ) : (
        <Button
          disabled={submitting.inProgress || isOrderEditedBySSE(order) || !isReady}
          className="accept-btn mr-4"
          onClick={() => {          
              setSubmitType(OrderSubmitType.PLACE);
              dispatch(submitOrder(orderId!, OrderSubmitType.PLACE));
          }}
          mainStyle={ButtonMainStyle.PrimaryRectangular}
        >
          Place order
        </Button>
      )}

      <Button
        disabled={submitting.inProgress || !isReady}
        className="send-back-btn mr-4"
        onClick={() => {
          setSubmitType(OrderSubmitType.RETURN);
          dispatch(submitOrder(orderId!, OrderSubmitType.RETURN));
        }}
        mainStyle={ButtonMainStyle.PrimaryOutline}
      >
        Send back
      </Button>

      <PresentForRoles roles={userPermissions.cancelOrderSSE}>
        <Button
          disabled={submitting.inProgress || !isReady}
          className="cancel-order-btn mr-4"
          onClick={() => setShowCancelModal(true)}
          mainStyle={ButtonMainStyle.PrimaryOutline}
        >
          Cancel order
        </Button>
      </PresentForRoles>      

      {submitting.error && (
        <div className="row no-gutters">
          <Alert>
            Error processing this order. Please try again later. If the problem persists, please contact your Account
            Manager.
          </Alert>
        </div>
      )}

      {submitting.inProgress && <Spinner className="submitting-spinner" text="Processing Order..." size="large" />}

      {showCancelModal && <CancelOrder onClose={() => setShowCancelModal(false)} />}

      {showCrfPlaceOrderModal && !submitting.inProgress && (
        <Modal
          title="Accepted order form?"
          onConfirm={() => {
            setShowCrfPlaceOrderModal(false);
            setSubmitType(OrderSubmitType.PLACE);
            dispatch(submitOrder(orderId!, OrderSubmitType.PLACE));
          }}
          onClose={() => { setShowCrfPlaceOrderModal(false); }}
          closeBtnLabel="Cancel"
        >
          Please note that this order requires a customer order form to be signed via the DocuSign process.
          <br /><br />
        </Modal>
      )}

      {prevInProgress && !submitting.inProgress && !submitting.error && (
        <Modal
          confirmBtnLabel="OK"
          title={submitType === OrderSubmitType.PLACE ? 'Order Accepted' : 'Order Sent Back'}
          onConfirm={goToOrders}
          onClose={goToOrders}
          showCloseBtn={false}
        >
          Thank you. This order has been successfully {submitType === OrderSubmitType.PLACE ? 'accepted' : 'sent back'}.
        </Modal>
      )}
    </div>
  );
};

const StyledSubmittedSSE = styled(SubmittedSSE)`
  .alert {
    margin-top: 1em;
  }

  .submitted-by-text {
    color: ${(props) => props.theme.colours.secondary};
  }

  .submitting-spinner {
    margin-top: 1.5em;
  }

  .primaryRectangular.larger-btn {
    width: 200px;
  }
`;

export default StyledSubmittedSSE;
