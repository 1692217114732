import React from 'react';
import Cost from './Cost';
import Column from '../../../../shared/components/atoms/Column';
import { OverallConnectionType } from '../../../../Quotes/types/connectionType';
import { ProductType } from '../../../../Quotes/types/productTypes';
import { PortCost } from 'Quotes/types/pricedQuote';

export function AEndPortCosts(props: {
  connectionType: OverallConnectionType;
  productType: ProductType;
  aEndPortCost: PortCost | null;
}) {
  return (
    <>
      {(props.connectionType === 'Ethernet' || props.productType === ProductType.OpticalP2P) && (
        <div className="row no-gutters mt-1">
          <Column defaultWidth={2} offsetDefaultWidth={2}>
            <span>Port</span>
          </Column>
          <Cost label="Install" cost={props.aEndPortCost?.install ?? 0} className="port-a-install" />
          <Cost label="Annual" cost={props.aEndPortCost?.annual ?? 0} className="port-a-annual" />
        </div>
      )}
    </>
  );
}
