import { isAfter, isBefore } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../theme/theme';
import { PromoOfferFields } from 'PromoOffer/promoTypes';
import cx from 'classnames';

function useIsImageVertical(imageUrl: string | undefined) {
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setIsVertical(img.height > img.width);
      };
    }
  }, [imageUrl]);

  return isVertical;
}

const UnstyledPromoBanner: FC<
  React.PropsWithChildren<PromoOfferFields & { className?: string; forceDisplay?: boolean; isPromo: boolean; }>
> = ({
  banner: { url: imageUrl, display: displayBanner },
  body,
  title,
  validFrom,
  validUntil,
  className,
  forceDisplay,
  isPromo,
  children
}) => {
  const isVertical = useIsImageVertical(imageUrl);

  const today = new Date();

  const isValid =
    validFrom && validUntil ? isAfter(today, new Date(validFrom)) && isBefore(today, new Date(validUntil)) : true;

  if (!forceDisplay && !isValid) return null;

  const displayImage = imageUrl && displayBanner;

  return (
    <div className={cx(className, { vertical: isVertical, horizontal: !isVertical })}>
      <div className={cx('content', { 'full-width': !displayImage }, { 'actions-box': !isPromo })}>
        <div className="badge">{theme.content.promotionsSlot.badgeText}</div>
        <h3 className="title">{title}</h3>
        <div className="body ql-editor-dev" dangerouslySetInnerHTML={{ __html: body }}></div>
        {children}
      </div>
      {displayImage && (
        <div className="image">
          <img alt="promotional banner image" src={imageUrl} />
        </div>
      )}
    </div>
  );
};
export const PromoBanner = styled(UnstyledPromoBanner)`
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  border-radius: 0.25em;  
  display: flex;
  overflow: hidden;

  &.plain, &.plain .actions-box, &.plain .actions-box p:last-child {
    box-shadow: none;
    background: none;
    padding: 0;
    margin: 0;
    display: block;
  }

  &.vertical {
    flex-flow: row nowrap;

    & .content {
      flex: 0 0 65%;
      &.full-width {
        flex-basis: 100%;
      }
    }

    & .image {
      flex: 1 0 35%;
    }
  }

  &.horizontal {
    flex-flow: column-reverse nowrap;
    & .content {
      flex: 1;
    }

    & .image {
      flex: 1;

      img {
        max-height: 100%;
        max-width: inherit;
      }
    }
  }

  .content {
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
    border: 4px solid ${(props) => props.theme.colours.primaryC1};

    .badge {
      background: ${(props) => props.theme.colours.primaryC1};
      border-radius: 26px;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 11px;
      line-height: 14px;
      padding: 5px 10px;
    }
  }

  .image {
    img {
      max-width: 100%;
      max-height: inherit;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .actions-box {
    &.content {
      border:  none;
    }
    &.content .badge{
      display:  none;
    }
    background-color: ${(props) => props.theme.colours.secondaryC5};
    box-shadow: ${(props) => props.theme.shadows.boxShadow};
    border-radius: 0.25em;
    padding: 2em;

    .useful-links {
      li {
        position: relative;
        padding-left: 25px;
        padding-bottom: 5px;
      }

      i {
        position: absolute;
        left: 0;
        top: 3px;
        color: ${(props) => props.theme.colours.primaryB1};
      }
    }
  }
`;
