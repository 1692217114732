import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  isNewNNI as selectIsNewNNI,
  isNewShadowNNI as selectIsNewShadowNNI,
  selectCurrentMeta,
  selectNNILabel,
  selectQuote,
  selectSelectedPrice,
} from 'Quotes/selectors';
import { concatenateShadowVlan } from 'shared/utils/addresses/helperFunctions';
import { selectOrder, selectOrderAddress } from 'Order/selectors';
import { useNewNNIRequests } from 'Order/OrderBuilder/Locations/shared/components/NNISummary';
import { StyledListItem } from 'Order/OrderBuilder/QuoteSummary/PoP/styles';
import { INewNNIRequest } from 'Order/types/location';
import {
  getSelectedSecondaryPriceFromPrice,
  useSecondaryCircuitsWithSelectedPrice,
} from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { ConnectionType, OverallConnectionType } from 'Quotes/types/connectionType';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import { SingleNNILocation } from 'Order/OrderBuilder/QuoteSummary/P2NNI/SingleNNILocation';
import { MultiNNILocations } from 'Order/OrderBuilder/QuoteSummary/P2NNI/MultiNNILocations';
import { getSecondaryNNILabel } from 'shared/utils/addresses/helperFunctions';

interface IP2NNIProps {
  isSSEUser: boolean;
  connectionType: OverallConnectionType;
  aEndAccessMethod: ConnectionType | null;
  bEndAccessMethod: ConnectionType | null;
  aEndBandwidth: string | null;
  bEndBandwidth: string | null;
  selectedPrice: IPriceData;
  bandwidth: string;
  quote: IQuote;
  bearer: BearerType | undefined;
  productType: ProductType;
}

export const NewNNIJobReference = ({
  jobReference,
  nniRequest: { hasNNIRequest, isNewNNIRequestAvailable },
}: {
  nniRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  jobReference: string | null | undefined;
}) => (
  <>
    {isNewNNIRequestAvailable && hasNNIRequest && (
      <h3>
        <em>New NNI Requested</em>
      </h3>
    )}
    {isNewNNIRequestAvailable && !hasNNIRequest && !!jobReference && (
      <StyledListItem className="font-weight-bold mr-3">New NNI Job Reference: {jobReference}</StyledListItem>
    )}
  </>
);

export const P2NNI: FC<React.PropsWithChildren<IP2NNIProps>> = ({ isSSEUser, bandwidth, bearer, productType }) => {
  const selectedPrice = useSelector(selectSelectedPrice);
  const secondarySelectedPrice = getSelectedSecondaryPriceFromPrice(selectedPrice);

  const quote = useSelector(selectQuote);
  const order = useSelector(selectOrder);
  const quoteMeta = useSelector(selectCurrentMeta);
  const shadowRef = concatenateShadowVlan(quoteMeta, quote.location.aEnd.nni);
  const nniLabel = useSelector(selectNNILabel);
  const { B: pointAddress } = useSelector(selectOrderAddress);
  const isNewNNI = useSelector(selectIsNewNNI);
  const isNewShadowNNI = useSelector(selectIsNewShadowNNI);

  const primarySiteConfig = order.locationA.siteConfig;
  const secondarySiteConfig = order.locationA.secondarySiteConfig;
  const newNNIRequests = primarySiteConfig.new_nni_requests;

  const shadowNNIRequest = useNewNNIRequests(
    isNewShadowNNI,
    quote.location.aEnd.nni.shadowVLAN.selectedDataCentreId,
    newNNIRequests
  );

  // Secondary Circuits
  const secondaryCircuits = useSecondaryCircuitsWithSelectedPrice();
  const isRO2 = featureFlag.isEnabled(Feature.SecondaryCircuits) && secondaryCircuits.isSupported();

  // Secondary NNI Data
  const secondaryCircuit = secondaryCircuits.selectedCircuit;
  const secondaryNniId = secondaryCircuit?.nniId;
  const nniData = secondaryCircuits.circuitData?.aEnd;
  const selectedSecondaryNNI =
    secondaryNniId && nniData && 'findNNI' in nniData ? nniData.findNNI(secondaryNniId) : undefined;
  const secondaryBEndPostcode = secondarySelectedPrice?.b_end_p_o_p_postcode;
  const secondaryBandwidth = secondarySelectedPrice?.bandwidth;

  // NNI Request
  const primaryNNIRequest = useNewNNIRequests(isNewNNI, selectedPrice.a_end_p_o_p_id, newNNIRequests);

  const secondaryIsNewNNI = !!selectedSecondaryNNI?.selectedId;
  const secondaryAEndPopId = secondarySelectedPrice?.a_end_p_o_p_id;
  const secondaryNewNNIRequests = order.locationA.secondarySiteConfig?.new_nni_requests;
  const secondaryNNIRequest = secondaryNewNNIRequests
    ? useNewNNIRequests(secondaryIsNewNNI, secondaryAEndPopId, secondaryNewNNIRequests)
    : undefined;

  const secondaryNNILabel = getSecondaryNNILabel(quote, selectedPrice);

  const requiredSecondaryData =
    secondaryNNIRequest && secondaryBEndPostcode && secondaryBandwidth && secondarySiteConfig && secondaryNNILabel;

  return (
    <>
      {isRO2 && requiredSecondaryData ? (
        <MultiNNILocations
          bandwidth={bandwidth}
          bearer={bearer}
          nniLabel={nniLabel}
          pointAddress={pointAddress}
          primaryNNIRequest={primaryNNIRequest}
          productType={productType}
          quote={quote}
          secondaryBandwidth={secondaryBandwidth}
          secondaryBearer={bearer}
          secondaryNNILabel={secondaryNNILabel}
          secondaryNNIRequest={secondaryNNIRequest}
          secondarySelectedPrice={secondarySelectedPrice}
          secondarySiteConfig={secondarySiteConfig}
          selectedPrice={selectedPrice}
          shadowNNIRequest={shadowNNIRequest}
          shadowRef={shadowRef}
          siteConfig={primarySiteConfig}
          sseUser={isSSEUser}
        />
      ) : (
        <SingleNNILocation
          bandwidth={bandwidth}
          bearer={bearer}
          nniLabel={nniLabel}
          nniRequest={primaryNNIRequest}
          pointAddress={pointAddress}
          productType={productType}
          quote={quote}
          selectedPrice={selectedPrice}
          shadowNNIRequest={shadowNNIRequest}
          shadowRef={shadowRef}
          siteConfig={primarySiteConfig}
          sseUser={isSSEUser}
        />
      )}
    </>
  );
};
