import {
  Circuit,
  Exchange,
  ExchangeRequestStatus,
  IPriceData,
  OpticalCosts,
  ProviderName,
  SecondaryCircuits,
} from '../../types/store';
import { IQuotePricedOrOrdered } from '../../types/quoteRecord';
import IPricedQuote, {
  APICircuit,
  APIDynamicPricing,
  APIOpticalCosts,
  APISecondaryCircuits,
  DynamicPriceData,
} from 'Quotes/types/pricedQuote';
import { PopResource } from 'Location/NNI/types';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import IQuoteRecordAttributesBase from 'Quotes/types/quoteRecordAttributesBase';

export function comparePrices(a: IPriceData, b: IPriceData): number {
  if (a.amortised_annual_price === null) {
    return 1;
  }

  if (b.amortised_annual_price === null) {
    return -1;
  }

  return a.amortised_annual_price - b.amortised_annual_price;
}

function parseToNumber(value: string | null): number | null {
  if (value && value !== '') {
    return parseFloat(value);
  }
  return null;
}

export function convertPriceDataFromQuotePrice(
  price: IQuotePricedOrOrdered,
  secondaryPoPs?: PopResource[],
  secondaryPrices?: IQuotePricedOrOrdered[]
): IPriceData {
  const { attributes } = price;

  return {
    quotation_id: price.attributes.quotation_id,
    is_poa: price.attributes.is_poa || false,
    a_end_access_type: attributes.a_end_access_type,
    a_end_annual_cost: parseToNumber(attributes.a_end_annual_cost),
    a_end_cabling_charge: attributes.a_end_cabling_charge ?? null,
    a_end_cabling_type: attributes.a_end_cabling_type ?? null,
    a_end_cross_connect_cost: attributes.a_end_cross_connect_cost ?? null,
    a_end_exchange_type: attributes.a_end_exchange_type ?? null,
    a_end_gea_cablelink_annual_cost: parseToNumber(attributes.a_end_gea_cablelink_annual_cost),
    a_end_gea_cablelink_install_cost: parseToNumber(attributes.a_end_gea_cablelink_install_cost),
    a_end_p_o_p: attributes.a_end_p_o_p,
    a_end_p_o_p_id: attributes.a_end_p_o_p_id,
    a_end_p_o_p_address: attributes.a_end_p_o_p_address,
    a_end_p_o_p_postcode: attributes.a_end_p_o_p_postcode,
    a_end_product_name: attributes.a_end_product_name ?? null,
    a_end_setup_cost: parseToNumber(attributes.a_end_setup_cost ?? null),
    amortised: attributes.amortised,
    amortised_annual_price: attributes.amortised_annual_price ?? null,
    annual_discount: attributes.annual_discount ?? 0,
    annual_ip_charge: attributes.annual_ip_charge ?? null,
    annual_price: parseToNumber(attributes.annual_price),
    annual_price_with_fttp_aggregation: attributes.annual_price_with_fttp_aggregation,
    annual_setup_price: parseToNumber(attributes.annual_setup_price),
    b_end_access_type: attributes.b_end_access_type,
    b_end_annual_cost: parseToNumber(attributes.b_end_annual_cost),
    b_end_cabling_charge: attributes.b_end_cabling_charge ?? null,
    b_end_cabling_type: attributes.b_end_cabling_type ?? null,
    b_end_cross_connect_cost: attributes.b_end_cross_connect_cost ?? null,
    b_end_exchange_type: attributes.b_end_exchange_type ?? null,
    b_end_gea_cablelink_annual_cost: parseToNumber(attributes.b_end_gea_cablelink_annual_cost),
    b_end_gea_cablelink_install_cost: parseToNumber(attributes.b_end_gea_cablelink_install_cost),
    b_end_p_o_p: attributes.b_end_p_o_p,
    b_end_p_o_p_postcode: attributes.b_end_p_o_p_postcode,
    b_end_product_name: attributes.b_end_product_name ?? null,
    b_end_setup_cost: parseToNumber(attributes.b_end_setup_cost ?? null),
    bandwidth: attributes.bandwidth ?? undefined,
    bandwidth_cost: {
      annual: attributes.bandwidth_cost?.annual ?? null,
      install: attributes.bandwidth_cost?.install ?? null,
    },
    cloud_connect: {
      provider: attributes.cloud_connect_options?.cloud_connect_pricing[0].provider || ProviderName.NOT_DEFINED,
      annual: attributes.cloud_connect_cost?.annual ?? null,
    },
    fttp_aggregation_charge: parseToNumber(attributes.fttp_aggregation_charge ?? null),
    id: price.id,
    install_discount: attributes.install_discount ?? 0,
    is_orderable: attributes.is_orderable ?? undefined,
    margin_amount: attributes.margin_amount ?? 0,
    margin_percentage: attributes.margin_percentage ?? 0,
    margin_percentage_with_fttp_aggregation: attributes.margin_percentage_with_fttp_aggregation,
    mdia_annual_cost: attributes.mdia_annual_cost ?? null,
    mdia_annual_price: attributes.mdia_annual_price ?? null,
    mdia_engineer_cost: attributes.mdia_engineer_cost ?? null,
    mdia_engineer_price: attributes.mdia_engineer_price ?? null,
    mdia_install_cost: attributes.mdia_install_cost ?? null,
    mdia_install_price: attributes.mdia_install_price ?? null,
    mdia_rack_mount_kit_cost: attributes.mdia_rack_mount_kit_cost ?? null,
    mdia_rack_mount_kit_price: attributes.mdia_rack_mount_kit_price ?? null,
    mdia_router_cost: attributes.mdia_router_cost ?? null,
    mdia_router_price: attributes.mdia_router_price ?? null,
    net_amortised_annual_price: attributes.net_amortised_annual_price ?? null,
    net_amortised_install_price: attributes.net_amortised_install_price ?? null,
    net_annual_price: attributes.net_annual_price ?? null,
    net_install_price: attributes.net_install_price ?? null,
    port_a_cost: attributes.port_a_cost,
    port_b_cost: attributes.port_b_cost,
    port_costs_annual: attributes.port_costs_annual ?? null,
    port_costs_setup: attributes.port_costs_setup ?? null,
    product_sub_type: attributes.product_sub_type ?? null,
    setup_price: parseToNumber(attributes.setup_price ?? null),
    shadow_vlan_price: parseToNumber(attributes.shadow_vlan_price ?? null),
    supplier_annual_cost: attributes.supplier_annual_cost ?? null,
    supplier_setup_cost: attributes.supplier_setup_cost ?? null,
    total_contract_value: attributes.total_contract_value ?? null,
    total_contract_value_with_fttp_aggregation: attributes.total_contract_value_with_fttp_aggregation,
    total_cost: attributes.total_cost ?? null,
    total_price: attributes.total_price ?? null,
    net_annual_price_with_fttp_aggregation: attributes.net_annual_price_with_fttp_aggregation,
    net_amortised_annual_price_with_fttp_aggregation: attributes.net_amortised_annual_price_with_fttp_aggregation,
    term_length_in_months: attributes.term_length_in_months ?? null,
    subnetPrices: attributes.ro2_dynamic_pricing_options,

    ...getOpticalCosts(attributes.optical_on_net_costs),
    ...getSecondaryCircuits(
      attributes.secondary_circuits,
      secondaryPoPs,
      secondaryPrices,
      attributes.ro2_dynamic_pricing_options
    ),
  };
}

const getOpticalCosts = (
  optical: APIOpticalCosts | undefined
): { opticalCosts: OpticalCosts } | Record<string, never> => {
  if (!optical) return {};

  return {
    opticalCosts: {
      aEnd: { annual: optical?.a_end?.annual ?? 0, install: optical?.a_end?.install ?? 0 },
      bEnd: { annual: optical?.b_end?.annual ?? 0, install: optical?.b_end?.install ?? 0 },
    },
  };
};

const mapSecondaryCircuitStatus = (apiStatus: APISecondaryCircuits['status']): ExchangeRequestStatus =>
  Object.values(ExchangeRequestStatus).find((status) => status === apiStatus) ?? ExchangeRequestStatus.NOT_REQUESTED;

const doesExist = (exchange: Exchange | undefined): exchange is Exchange => !!exchange;

function toExchange(
  exchangeData: { pop_id: string; distance: string },
  secondaryPoPs: PopResource[]
): Exchange | undefined {
  const pop = secondaryPoPs.find((pop) => pop.id === exchangeData.pop_id);
  if (!pop) return undefined;
  return {
    popId: pop.id,
    name: pop.attributes.name ?? pop.attributes.location ?? 'Unknown name', // Todo: this should be impossible, but the type system allows it. Consider removing the '| null' from the name type
    distance: parseInt(exchangeData.distance, 10),
  };
}

// Example input: nni-123_datacentre-456
const splitEnds = (circuitId: string) => circuitId.split('_');
export const parseAEndId = (circuitId: string) => splitEnds(circuitId)[0];
export const parseBEndId = (circuitId: string): string | undefined => splitEnds(circuitId)[1];
// const extractType = (idSegment: string) => idSegment.split('-')[0];
export const extractValue = (idSegment: string | undefined) => idSegment?.split('-')[1];

function mapSecondaryCircuits(
  secondaryCircuits: APISecondaryCircuits,
  secondaryPoPs: PopResource[] | undefined,
  secondaryPrices: IQuotePricedOrOrdered[] | undefined,
  dynamicPrices: IPricedQuote['ro2_dynamic_pricing_options']
): SecondaryCircuits {
  const status = mapSecondaryCircuitStatus(secondaryCircuits.status);
  const bEndExchanges = secondaryCircuits.exchanges.b_end
    ?.map((it) => toExchange(it, secondaryPoPs ?? []))
    .filter(doesExist);
  const aEndExchanges = secondaryCircuits.exchanges.a_end
    ?.map((it) => toExchange(it, secondaryPoPs ?? []))
    .filter(doesExist);

  const selectedCircuitId = secondaryCircuits.selected_circuit_id;

  return {
    enabled: secondaryCircuits.ro2_enabled ?? true,
    status: status,
    selectedCircuitId: selectedCircuitId,
    selectedAEndId: selectedCircuitId ? extractValue(parseAEndId(selectedCircuitId)) : undefined,
    selectedBEndId: selectedCircuitId ? extractValue(parseBEndId(selectedCircuitId)) : undefined,
    aEndExchanges: aEndExchanges ?? [],
    bEndExchanges: bEndExchanges ?? [],
    circuits:
      (secondaryCircuits.circuits &&
        Object.entries(secondaryCircuits.circuits).map(([id, circuit]) => toCircuit(id, circuit, secondaryPrices))) ??
      [],
    dynamicPrices: toDynamicPrices(dynamicPrices),
  };
}

interface DynamicPricing {
  totalContractValue?: number;
  totalContractValueWithFttpAggregation: number | null;
  marginPercentage?: number;
  marginPercentageWithFttpAggregation: number | null;
  grossMarginAmount?: number;
  netAnnualPrice?: number;
  netAmortisedAnnualPrice?: number;
}

const toDynamicPrice = (priceData: DynamicPriceData | undefined): DynamicPricing | undefined => {
  if (!priceData) return undefined;

  return {
    totalContractValue: priceData.total_contract_value,
    totalContractValueWithFttpAggregation: priceData.total_contract_value_with_fttp_aggregation,
    marginPercentage: priceData.margin_percentage,
    marginPercentageWithFttpAggregation: priceData.margin_percentage_with_fttp_aggregation,
    grossMarginAmount: priceData.gross_margin_amount,
    netAnnualPrice: priceData.net_annual_price,
    netAmortisedAnnualPrice: priceData.net_amortised_annual_price,
  };
};

// type CalculationFn = (quote: IQuoteRecordAttributesBase) => number;
export type DynamicPrices = {
  subnetPrices: Record<IPType, DynamicPricing | undefined>;
  // dynamicFields: Record<DynamicFieldOperation['result_key'], CalculationFn>;
};

export type DynamicPriceComponent =
  | { value: number; condition_key: keyof IQuoteRecordAttributesBase }
  | {
      value: Record<string, number>;
      condition_key: keyof IQuoteRecordAttributesBase;
      origin_key: 'mdia_cpe_selection';
    };

// const createCalculationFn = (dynamicPriceField: DynamicFieldOperation): CalculationFn => {
//   return (quote: IQuoteRecordAttributesBase) =>
//     dynamicPriceField.components.reduce((result, component) => {
//       const value = 'origin_key' in component ? component.value[quote[component.origin_key]!] : component.value;
//
//       if (component.condition_key) {
//         const shouldApply = !!quote[component.condition_key];
//         return shouldApply ? result + value : result;
//       }
//       return result + value;
//     }, 0);
// };

const toDynamicPrices = (dynamicPrices: APIDynamicPricing | undefined): DynamicPrices => {
  // const calculations: DynamicPrices['dynamicFields'] = dynamicPrices?.calculation_rules.reduce(
  //   (result: Partial<DynamicPrices['dynamicFields']>, rule) => {
  //     result[rule.result_key] = createCalculationFn(rule);
  //     return result;
  //   },
  //   {}
  // ) as DynamicPrices['dynamicFields'];

  return {
    // dynamicFields: [],
    subnetPrices: {
      [IPType.STATIC_16]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.STATIC_16]),
      [IPType.STATIC_4]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.STATIC_4]),
      [IPType.STATIC_8]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.STATIC_8]),
      [IPType.WAN]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.WAN]),
      [IPType.SUBNET_26]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.SUBNET_26]),
      [IPType.SUBNET_27]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.SUBNET_27]),
      [IPType.SUBNET_28]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.SUBNET_28]),
      [IPType.SUBNET_29]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.SUBNET_29]),
      [IPType.SUBNET_30]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.SUBNET_30]),
      [IPType.SUBNET_31]: toDynamicPrice(dynamicPrices?.pre_calculated?.ip_count?.[IPType.SUBNET_31]),
    },
  };
};

export const toCircuit = (
  id: string,
  api: APICircuit,
  secondaryPrices: IQuotePricedOrOrdered[] | undefined
): Circuit => {
  return {
    exchangeId: api.b_end?.id,
    id: id,
    nniId: api.a_end.id,
    prices:
      api.status === undefined
        ? undefined
        : secondaryPrices
            ?.filter((it) => api.price_ids?.includes(it.id))
            .map((secondaryPrice) => convertPriceDataFromQuotePrice(secondaryPrice)),
    selectedPriceId: api.selected_price_id,
  };
};

function getSecondaryCircuits(
  secondaryCircuits: IPricedQuote['secondary_circuits'],
  secondaryPoPs: PopResource[] | undefined,
  secondaryPrices: IQuotePricedOrOrdered[] | undefined,
  dynamicPrices: IPricedQuote['ro2_dynamic_pricing_options']
): { secondary_circuits?: SecondaryCircuits } {
  return !secondaryCircuits
    ? {}
    : {
        secondary_circuits: mapSecondaryCircuits(secondaryCircuits, secondaryPoPs, secondaryPrices, dynamicPrices),
      };
}

export const calculateAnnualPrice = (
  amortised: boolean,
  netAmortisedAnnualPrice: number,
  netAnnualPrice: number
): number => {
  return amortised ? netAmortisedAnnualPrice : netAnnualPrice;
};

export const calculateUpFront = (
  amortised: boolean,
  netAmortisedInstallPrice: number,
  netInstallPrice: number
): number => {
  return amortised ? netAmortisedInstallPrice : netInstallPrice;
};

const numberOfMonths = 36;
export const canAmortise = (contractTerm: string | number, setupPrice: number): boolean => {
  const term = typeof contractTerm === 'number' ? contractTerm : parseToNumber(contractTerm) ?? 0;
  return term >= numberOfMonths && setupPrice !== 0;
};
