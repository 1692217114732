import React from 'react';
import { ISiteConfig } from 'Order/types/location';
import Column from 'shared/components/atoms/Column';
import TextInput from 'shared/components/atoms/TextInput';

interface IShadowNNIInputFieldsProps {
  identifierPrefix: string;
  siteConfig: ISiteConfig;
  onChange: (field: keyof ISiteConfig, value: any) => void;
}

export const ShadowNNIInputFields: React.FC<IShadowNNIInputFieldsProps> = ({
  identifierPrefix,
  siteConfig,
  onChange,
}) => (
  <div className="row">
    <Column defaultWidth={6}>
      <TextInput
        inputIdentifierPrefix={`${identifierPrefix}`}
        fieldName="_shadow_nni_job_reference"
        onChange={(e) => onChange('shadow_nni_job_reference', e.target.value)}
        labelText="NNI Job Reference"
        value={siteConfig.shadow_nni_job_reference || ''}
        optional
      />
    </Column>
    <Column defaultWidth={6}>
      <TextInput
        inputIdentifierPrefix={`${identifierPrefix}`}
        fieldName="_shadow_nni_idn_number"
        onChange={(e) => onChange('shadow_nni_idn_number', e.target.value)}
        labelText="IDN"
        value={siteConfig.shadow_nni_idn_number || ''}
        optional
      />
    </Column>
  </div>
);
