import { IOrderLocation, ISiteConfig } from 'Order/types/location';
import {
  isValidCoords,
  isValidEmail,
  isValidNoteField,
  isValidPhoneField,
  isValidTextField,
  isValidVLANIdField,
} from './validation';
import { isManualAddress } from 'shared/utils/addresses/helperFunctions';
import { AddressType, CablingType, IQuote } from 'Quotes/types/store';
import { isFTTXQuote } from 'Quotes/utils/isFTTXQuote';
import { ProductType } from 'Quotes/types/productTypes';

function getConfigIsComplete(quote: IQuote, siteConfig: ISiteConfig, cablingType?: CablingType | null) {
  const fttxQuote = isFTTXQuote(quote);

  if(quote.productType === ProductType.OpticalP2P && cablingType !== undefined) {
    const showOpticalCabling = cablingType === CablingType.Fibre ? "yes" : "no";
    if(showOpticalCabling === "no") {
      if(!siteConfig.connectorType) return false;
      if(!siteConfig.mediaType) return false;
      return true;
    }
  }

  return (
    (fttxQuote ? true : siteConfig.connectorType) &&
    (fttxQuote ? true : siteConfig.mediaType) &&
    (fttxQuote ? true : siteConfig.powerType) &&
    (!siteConfig.vlanTagging ? true : isValidVLANIdField(siteConfig.vlanId)) &&
    isValidTextField(siteConfig.floor) &&
    isValidTextField(siteConfig.room) &&
    isValidTextField(siteConfig.rackId)
  );
}

export function isCompleted(
  {
    locationData: { fullAddress, addressType, fullAddressNotListed, pafAddressNotListed },
    endCompanyName,
    siteReadiness,
    siteConfig,
    secondarySiteConfig,
    siteContact,
    siteCoords,
  }: IOrderLocation,
  quote: IQuote,
  isNNAT: boolean,
  cablingType?: CablingType | null
): boolean {
  const readinessIsComplete =
    (siteReadiness.hazardsOnSite &&
      isValidTextField(siteReadiness.hazardsOnSiteDescription) &&
      isValidNoteField(siteReadiness.hazardsOnSiteDescription || '')) ||
    !siteReadiness.hazardsOnSite;

  if (!readinessIsComplete) {
    return false;
  }

  const configIsComplete = getConfigIsComplete(quote, siteConfig, cablingType);
  const secondarySiteConfigIsComplete = secondarySiteConfig ? getConfigIsComplete(quote, secondarySiteConfig) : true;

  if (!configIsComplete || !secondarySiteConfigIsComplete) {
    return false;
  }

  const siteIsComplete =
    isValidTextField(siteContact.firstName) &&
    isValidTextField(siteContact.surname) &&
    isValidPhoneField(siteContact.phone) &&
    isValidEmail(siteContact.email) &&
    isValidNoteField(siteContact.notes);

  if (!siteIsComplete) {
    return false;
  }

  if (!isValidTextField(endCompanyName)) {
    return false;
  }

  // Manual address requires coordinates
  if (
    (fullAddressNotListed || pafAddressNotListed) &&
    !isValidCoords({
      lon: siteCoords.longitude,
      lat: siteCoords.latitude,
      easting: siteCoords.easting,
      northing: siteCoords.northing,
    })
  ) {
    return false;
  }

  const eastingsNorhingsIsComplete =
    siteCoords.easting || siteCoords.northing
      ? isValidTextField(siteCoords.easting) && isValidTextField(siteCoords.northing)
      : true;
  const latLonIsComplete =
    siteCoords.latitude || siteCoords.longitude
      ? isValidTextField(siteCoords.latitude) && isValidTextField(siteCoords.longitude)
      : true;

  if (!eastingsNorhingsIsComplete || !latLonIsComplete) {
    return false;
  }

  if (
    quote.productType === ProductType.OpticalP2P ||
    !isManualAddress(fullAddress, addressType) ||
    (addressType === AddressType.PAF && isNNAT)
  ) {
    return true;
  }

  return (
    (isValidTextField(fullAddress.building_name) || isValidTextField(fullAddress.building_number)) &&
    isValidTextField(fullAddress.county) &&
    isValidTextField(fullAddress.post_town) &&
    isValidTextField(fullAddress.postcode) &&
    isValidTextField(fullAddress.street)
  );
}
