import { Feature } from 'FeatureFlags/types';
import hasFeatureEnabled from 'FeatureFlags/utils/hasFeatureEnabled';
import { orderIdSelector, shortIdSelector } from 'Order/selectors';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { saveAs } from 'file-saver';
import { doRequest } from 'Request';
import { decode } from 'base64-arraybuffer';
import Spinner from 'shared/components/atoms/Spinner';
import styled from 'styled-components';
import cx from 'classnames';
import { capitalize } from 'lodash';
import getCustomer from 'Admin/crud/getCustomer';
import { selectSelectedCompanyId } from 'User/selectors';

type Props = {
  className?: string;
  buttonText?: string;
  disabled?: boolean;
  type?: 'pdf' | 'crf';
};

const format_order_form_filename = (orderId: string): string => {
  const now = new Date();
  
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  
  return `${year}${month}${day}_${hours}${minutes}_${orderId}.pdf`;
};

export const DownloadOrder: FC<React.PropsWithChildren<Props>> = ({ 
    className,
    buttonText = 'Download',
    disabled = false,
    type = 'pdf',
  }) => {

  const companyId = useSelector(selectSelectedCompanyId);
  const orderId = useSelector(orderIdSelector);
  const shortOrderId = useSelector(shortIdSelector);
  const [pending, setPending] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (type === "pdf")
      setVisible(hasFeatureEnabled(Feature.orderDownload));
  }, [type]);

  useEffect(() => {
    (async () => {
      if(type !== "crf") return;
    
      setPending(true);

      try {
        const response = await getCustomer(companyId);
        if (response.data.attributes.manual_crf === true) {
          // hide self if not enabled
          setVisible(true);
        }
      } catch (e) {
        // Silently fail until we have requirements specified for an error scenario.
      }

      setPending(false);
    })();
  }, [companyId, type]);
  
  return (
    <>
      {visible && (
        <Button
          className={cx(className, { loading: pending })}
          disabled={disabled || pending}
          mainStyle={ButtonMainStyle.Secondary}
          onClick={() => {
            (async () => {
              setPending(true);

              try {
                const base64 = await doRequest({
                  path: `/orders/${orderId}/` + type,
                });
                const blob = new Blob([decode(base64)], {
                  type: 'application/pdf',
                });
                const fileName = format_order_form_filename(capitalize(shortOrderId));
                saveAs(blob, fileName);
              } catch (e) {
                // Silently fail until we have requirements specified for an error scenario.
              } finally {
                setPending(false);
              }
            })();
          }}
        >
          {pending && <Spinner size="small" colour="secondary" />}
          {!pending && buttonText}
        </Button>
      )}
    </>
  );
};

export default styled(DownloadOrder)`
  min-width: 115px;

  &.loading {
    padding-top: 0;
  }
`;
