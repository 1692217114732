import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp, useSelector } from 'react-redux';
import { IAppState } from 'reducers';
import { CardState } from 'shared/components/organisms/CardWithInitialText';
import { IOrderLocation, LocationLetter } from 'Order/types/location';
import AEndLocationContainer from './AEndLocation';
import CardWrapper from '../shared/components/CardWrapper';
import { IOrder, IOrderMeta } from 'Order/types/store';
import { CablingType, IQuote } from 'Quotes/types/store';
import { editOrderAction } from 'Order/actions';
import { pagesWithType, trackPage } from 'shared/utils/trackPage';
import { ProductType } from 'Quotes/types/productTypes';
import { isCompleted } from '../../shared/utils/isCompleted';
import isNNICompleted from '../../shared/utils/isNNICompleted';
import quoteHasShadowNNI from '../../shared/utils/hasShadowNNI';
import { isNewNNI, isNewShadowNNI, selectNNILabel } from 'Quotes/selectors';
import { CopyFromQuoteButton } from 'Order/OrderBuilder/CopyFromQuote/CopyFromQuoteButton';
import { isSupplierNNAT } from 'Quotes/utils/isPriceNNAT';

interface IAEndLocation {
  order: IOrder;
  quote: IQuote;
  orderId: string;
  isNNAT: boolean;
  hasShadowNNI: boolean;
  cardState: CardState;
  saving?: boolean;
  onSaveClick(orderId: string, locationA: IOrderLocation, locationB: IOrderLocation, orderMeta: IOrderMeta): void;
  setCardState(state: CardState): void;
  cablingType?: CablingType | null;
}

const copyFeatureDisallowedProductTypes = [ProductType.P2NNI, ProductType.NNI2CCT];

export const AEndLocation: FunctionComponent<React.PropsWithChildren<IAEndLocation>> = ({
  quote,
  order,
  orderId,
  isNNAT,
  saving,
  cardState,
  hasShadowNNI,
  onSaveClick,
  setCardState,
  cablingType
}) => {
  useEffect(() => {
    if (order.productType && cardState === CardState.Edit) {
      trackPage(pagesWithType.orderALocation(order.productType));
    }
  }, [order.productType, cardState]);
  const nniLabel = useSelector(selectNNILabel);
  const { locationA, locationB } = order;
  const newNNI = useSelector(isNewNNI);
  const newShadowNNI = useSelector(isNewShadowNNI);

  let locationDescription;
  let isNNI = false;
  const postcode = locationA.locationData.readonly_postcode;
  const locationLetter = order.productType === ProductType.DIA ? LocationLetter.B : LocationLetter.A;

  if (order.productType === ProductType.P2NNI || order.productType === ProductType.NNI2CCT) {
    isNNI = true;
    locationDescription = nniLabel;
  } else {
    locationDescription = postcode;
  }

  return (
    <CardWrapper
      cardState={cardState}
      isNNI={isNNI}
      isNewNNI={newNNI || newShadowNNI}
      locationLetter={locationLetter}
      completed={isNNI ? isNNICompleted(order, hasShadowNNI) : isCompleted(locationA, quote, isNNAT, cablingType)}
      saving={saving}
      onSaveClick={() => onSaveClick(orderId, locationA, locationB, order.orderMeta)}
      locationDescription={locationDescription}
      setCardState={setCardState}
      extraButtons={
        !copyFeatureDisallowedProductTypes.includes(order.productType!) &&
        !!quote.bulkQuoteId && (
          <CopyFromQuoteButton
            orderId={orderId}
            bulkQuoteId={quote.bulkQuoteId}
            sectionToCopy="a-end"
            onCopyComplete={() => {
              setCardState(CardState.Edit);
            }}
          />
        )
      }
    >
      <AEndLocationContainer order={order} quote={quote} postcode={postcode} cardState={cardState} isNNI={isNNI} />
    </CardWrapper>
  );
};

const mapStateToProps = ({
  quoteBuilder: {
    quote,
    quoteEndpointMeta,
    pricing: {
      selectedPrice: { a_end_access_type, a_end_cabling_type },
    },
  },
  orderBuilder: {
    id: orderId,
    updating: { inProgress },
    order,
  },
}: IAppState) => ({
  orderId: orderId!,
  order: order!,
  quote: quote!,
  isNNAT: isSupplierNNAT(a_end_access_type),
  selectedPrice: quote!,
  saving: inProgress,
  orderMeta: order.orderMeta,
  hasShadowNNI: quoteHasShadowNNI(quoteEndpointMeta, quote.location.aEnd.nni),
  cablingType: a_end_cabling_type
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  onSaveClick: (orderId: string, locationA: IOrderLocation, locationB: IOrderLocation, orderMeta: IOrderMeta) =>
    dispatch(editOrderAction(orderId, { locationA, locationB, orderMeta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AEndLocation);
