import React from 'react';
import Cost from './Cost';
import Column from '../../../../shared/components/atoms/Column';

export function CostRow(props: {
  label: string;
  install: number;
  annual: number;
}) {
  return (
    <div className="row no-gutters mt-3">
      <Column defaultWidth={2} offsetDefaultWidth={2}>
        <span>{props.label}</span>
      </Column>
      <Cost label="Install" cost={props.install} className="install" />
      <Cost label="Annual" cost={props.annual} className="annual" />
    </div>
  );
}
