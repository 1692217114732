import React, { FunctionComponent, useEffect, useState } from 'react';
import { ProductType } from 'Quotes/types/productTypes';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import TermsText, { OPENREACH_SURCHARGE_TEXT } from './components/TermsText';
import { IRequestState } from 'shared/types/requestState';
import { useSelector } from 'react-redux';
import { selectSelectedCompanyId } from 'User/selectors';
import getCustomer from 'Admin/crud/getCustomer';

type Props = {
  productType: ProductType;
  showGeaCablelinkNotice: boolean;
  showOpenreachCPENotice: boolean;
  showAdditionalOpenreachSurchargeNotice: boolean;
  submitting: IRequestState;
  onSubmit(): void;
  onRequestOrderForm(): void;
};

const Terms: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onSubmit,
  productType,
  showGeaCablelinkNotice,
  showOpenreachCPENotice,
  showAdditionalOpenreachSurchargeNotice,
  submitting,
  onRequestOrderForm
}) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openreachCPEAccepted, setOpenreachCPEAccepted] = useState(!showOpenreachCPENotice);
  const [openreachSurchargeAccepted, setOpenreachSurchargeAccepted] = useState(false);
  const onChangeOpenreachCPE = () => {
    setOpenreachCPEAccepted(!openreachCPEAccepted);
  };
  const onChangeOpenreachSurcharge = () => {
    setOpenreachSurchargeAccepted(!openreachSurchargeAccepted);
  };
  const onChangeTermsChkBox = () => {
    setTermsAccepted(!termsAccepted);
  };

  const companyId = useSelector(selectSelectedCompanyId);
  const [crfAvailable, setCrfAvailable] = useState(false);
  useEffect(() => {
    (async () => {

      // Allow for P2NNI only for now
      if (productType !== ProductType.P2NNI) return;

      try {
        const response = await getCustomer(companyId);
        if (response.data.attributes.manual_crf === true) {
          // hide self if not enabled
          setCrfAvailable(true);
        }
      } catch (e) {
        // Silently fail until we have requirements specified for an error scenario.
      }
    })();
  }, [companyId, productType]);

  return (
    <div>
      <TermsText
        productType={productType}
        showGeaCablelinkNotice={showGeaCablelinkNotice}
        showOpenreachCPENotice={showOpenreachCPENotice}
        showAdditionalOpenreachSurchargeNotice={showAdditionalOpenreachSurchargeNotice}
        onToggleTerms={onChangeTermsChkBox}
      />

      {showOpenreachCPENotice && (
        <div className="form-check pb-1">
          <input
            type="checkbox"
            id="openreach-cpe-checkbox"
            checked={openreachCPEAccepted}
            className="form-check-input"
            onChange={onChangeOpenreachCPE}
          />
          <label htmlFor="openreach-cpe-checkbox" className="form-check-label">
            Please tick to confirm your device(s) is MCT compliant.
          </label>
        </div>
      )}

      {showAdditionalOpenreachSurchargeNotice && (
        <div className="form-check pb-3">
          <input
            type="checkbox"
            id="openreach-surcharge-checkbox"
            checked={openreachSurchargeAccepted}
            className="form-check-input"
            onChange={onChangeOpenreachSurcharge}
          />
          <label htmlFor="openreach-surcharge-checkbox" className="form-check-label">
            {OPENREACH_SURCHARGE_TEXT}
          </label>
        </div>
      )}

      {(crfAvailable && <Button
        disabled={
          !openreachCPEAccepted ||
          (showAdditionalOpenreachSurchargeNotice && !openreachSurchargeAccepted) ||
          submitting.inProgress
        }
        onClick={onRequestOrderForm}
        mainStyle={ButtonMainStyle.Primary}
        subStyle="outline"
        className="cancel-order-btn"
      >
        Request order form
      </Button>
      )}

      <Button
        disabled={
          !termsAccepted ||
          !openreachCPEAccepted ||
          (showAdditionalOpenreachSurchargeNotice && !openreachSurchargeAccepted) ||
          submitting.inProgress
        }
        onClick={onSubmit}
        className="submitOrder--btn"
      >
        Submit for review
      </Button>
    </div>
  );
};

export default Terms;
