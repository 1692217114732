import { IAppState } from 'reducers';
import { CompanyData } from './types/user';
import { BackendRoleName, Role } from './types/role';
import { anyRolesMatch } from './utils/roles';
import { userPermissions } from 'shared/utils/permissions';
import { isSSEUserFromBackendRoles } from 'Admin/utils/companyRolesRows';
import { intersection } from 'lodash';

export const selectNameOfSelectedCompany = (state: IAppState): string => {
  const selectedCompanyId = state.user.companyData.selectedCompanyId;
  return state.user.companyData.companies.filter((company) => company.id === selectedCompanyId)[0]?.name;
};

export const selectCompanyData = (state: IAppState): CompanyData => state.user.companyData;

export const selectSelectedCompanyId = (state: IAppState): string => selectCompanyData(state).selectedCompanyId!;

export const selectIsAuthorised = (allowedRoles: Role[], state: IAppState): boolean =>
  anyRolesMatch(allowedRoles, state.user.userData.roles);

export const selectUserCanPlaceOrder = (state: IAppState) => {
  return anyRolesMatch(userPermissions.placeOrder, state.user.userData.roles);
};

export const hasCustAdminRole = (state: IAppState) => {
  return state.user.userData.roles.includes(Role.Admin);
};

export const hasSSEAdminRole = (state: IAppState) => {
  return state.user.userData.roles.includes(Role.SSEAdmin);
};

export const hasSSEPricingRole = (state: IAppState) => {
  return state.user.userData.roles.includes(Role.SSEPricing);
};

export const hasSSEAdminWildcardRole = (state: IAppState) => {
  return state.user.userData.wildcardRoles.includes(BackendRoleName.SSEAdmin);
};

export const selectUserRoles = (state: IAppState) => {
  return state.user.userData.roles;
};

export const hasSSEPricingWildcardRole = (state: IAppState) => {
  return state.user.userData.wildcardRoles.includes(BackendRoleName.SSEPricing);
};

export const selectIsInternalUser = (state: IAppState) => {
  const { allRoles } = state.user;
  return isSSEUserFromBackendRoles(allRoles);
};

export const selectHasCostsPermissions = (state: IAppState) => {
  return intersection(state.user.userData.roles, userPermissions.costs).length > 0;
};

export const selectHasViewPromoOffersPermissions = (state: IAppState) => {
  return intersection(state.user.userData.roles, userPermissions.viewPromoOffers).length > 0;
};

export const selectHasEditPromoOffersPermissions = (state: IAppState) => {
  return intersection(state.user.userData.roles, userPermissions.editPromoOffers).length > 0;
};

export const selectActiveUserId = (state: IAppState) => {
  return state.user.userData.id;
};

export const selectUnreadMessageCount = (state: IAppState) => state.user.userData.unreadMessages;
