import React from 'react';
import {
  PriceTile as InternalPriceTile,
  PriceTileReduxParams,
} from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/PriceTile';
import styled from 'styled-components';
import { Circuit } from 'Quotes/types/store';
import { Slider } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider';
import { useSelector } from 'react-redux';
import { selectQuote, selectSelectedPrice } from 'Quotes/selectors';
import { getSecondaryNNILabel } from 'shared/utils/addresses/helperFunctions';

const DisplayPricesTile = styled.div`
  width: 323px;
  background: ${(props) => props.theme.colours.secondaryC5};
`;

const DisplayPricesContainer = styled.div`
  background: ${(props) => props.theme.colours.secondaryC5};
  border: 1px solid ${(props) => props.theme.colours.primaryB2};
  border-radius: 0.5rem;
`;

export const ShowPrices = ({
  circuit,
  selectPrice,
  readOnly = false,
  Tile = InternalPriceTile,
}: {
  circuit?: Circuit | undefined;
  selectPrice: (priceId: string) => void;
  readOnly?: boolean;
  Tile?: typeof InternalPriceTile;
}) => {
  if (!circuit) return null;

  // Secondary Circuit Data
  const secondaryPrices = circuit.prices ?? [];

  // Quote and Bearer Data
  const quote = useSelector(selectQuote);
  const bEndPostcode = quote.location.bEnd.postcode;
  const productType = quote.productType;

  const selectedPriceForLabel = useSelector(selectSelectedPrice);
  const secondaryNNILabel = getSecondaryNNILabel(quote, selectedPriceForLabel);

  const secondaryPriceTileParams = (): PriceTileReduxParams => {
    return {
      aEndBandwidth: null,
      aEndDataCentreName: null,
      aEndNniShadowDataCentreReference: undefined,
      aEndNniShadowReference: undefined,
      aEndPostcode: '',
      bEndBandwidth: null,
      bEndDataCentreName: null,
      bEndDataCentreReference: undefined,
      bEndPostcode: bEndPostcode,
      dataCentreReferenceAEnd: undefined,
      nniLabel: secondaryNNILabel,
      nniLocation: undefined,
      nniPopName: undefined,
      nniReference: undefined,
      productType: productType,
      shadowVlanLocation: undefined,
    };
  };

  return (
    <DisplayPricesContainer data-testid="show-prices-element">
      <Slider
        prices={secondaryPrices}
        selectedPriceId={circuit.selectedPriceId}
        readonly={readOnly}
        slidesToShow={4}
        slidesToScroll={3}
      >
        {secondaryPrices.map((priceData, index) => {
          const secondaryPriceData = secondaryPriceTileParams();
          return (
            <DisplayPricesTile key={index}>
              <Tile
                readOnly={readOnly}
                priceData={priceData}
                priceSelected={priceData.id === circuit.selectedPriceId}
                onClick={() => undefined}
                onPriceItemClick={(priceId: string) => !readOnly && selectPrice(priceId)}
                supportsSecondaryCircuits={false}
                {...secondaryPriceData}
              />
            </DisplayPricesTile>
          );
        })}
      </Slider>
    </DisplayPricesContainer>
  );
};
